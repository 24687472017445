export enum ResidenceMode {
  DEFAULT = 'default',
  INTERNATIONAL = 'international',
  GRADUATES = 'graduates',
}
export interface IAdmRequiredDocument {
  key: string;
  required: {
    firstEnrollment: boolean;
    alreadyEnrolled: boolean;
    hasBachelor: boolean;
  };
  textToDisplay: string;
}

export interface IResidence {
  _id: string;
  name: string;
  emails: string[];
  createdAt: Date;
  updatedAt: Date;
  promoCodeEnabled?: boolean;
  admRequiredDocuments: IAdmRequiredDocument[];
  mode: ResidenceMode;
}

export interface IAppeal {
  _id: string;
  residence_id: string;
  acceptCandidacyFromDate?: Date;
  date: Date;
  expiryDate: Date;
  createdAt: Date;
  updatedAt: Date;
}
