import React, { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { registrySchema, registryType } from 'types/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { StepComponentProps } from 'types';
import FormStepManager from 'components/Application/Form/FormStepManager/FormStepManager';
import BlankLoader from 'components/Shared/Spinner/BlankLoader';

import DatePicker from 'react-datepicker';
import { CustomDatePickerInput } from 'components/reactDatepicker';
import dayjs from 'dayjs';
import { FaFingerprint } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ResidenceMode } from 'types/api/residences';
import { useTranslation } from 'react-i18next';

// setDefaultLocale(it);

function DescriptionBar({
  formState,
  requiredFields,
  nascondiIseeTraLeScelte_maPerche,
}: {
  formState: FormState<registryType>;
  requiredFields: number;
  nascondiIseeTraLeScelte_maPerche: boolean;
}) {
  const { t } = useTranslation();
  const { errors, isValid: isFormValid } = formState;
  const errorsNumber = Object.keys(errors).length;
  const progressPercentage = ((requiredFields - errorsNumber) / requiredFields) * 100;
  // console.log(progressPercentage)
  return (
    <div className="animate-fade-in-down md:animate-fade-in-left mt-4 md:mt-0 flex w-full md:w-4/12 flex-col items-center md:items-start gap-4">
      <h3 className="flex flex-row items-center text-black font-bold text-xl">
        <FaFingerprint />
        <span className="pl-2">{t('DescriptionBar.a1')}</span>
      </h3>
      <p className="text-black font-medium text-md">{t('DescriptionBar.a2')}</p>
      <ul className="list-disc text-black font-medium text-md pl-4">
        <li>{t('DescriptionBar.a3')}</li>
        {!nascondiIseeTraLeScelte_maPerche ? <li>{t('DescriptionBar.a4')}</li> : null}
      </ul>
    </div>
  );
}
export default function Registry({
  steps,
  currentStep,
  nextStep,
  prevStep,
  profile,
  profileFetchStatus,
  applicationAppeal,
  applicationAppealResidence,
  setProfile,
}: StepComponentProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, setValue, trigger, reset, getValues, watch, handleSubmit, control, formState } =
    useForm<registryType>({
      resolver: zodResolver(registrySchema),
      defaultValues: {
        firstName: undefined,
        lastName: undefined,
        fiscalCode: undefined,
        placeOfBirth: undefined,
        birthday: undefined,
        citizenship: undefined,
        secondCitizenship: undefined,
        notSubmitIsee: undefined,
        isee: undefined,
        address: {
          street: undefined,
          state: undefined,
          number: undefined,
          zipCode: undefined,
          district: undefined,
          city: undefined,
        },
        phoneNumber: undefined,
        cellNumber: undefined,
      },
      mode: 'all',
    });

  const { errors } = formState;

  const [isSteploading, setIsStepLoading] = useState<boolean>(true);

  const fieldRef = React.useRef<HTMLInputElement>(null);

  const watchNotSubmitIsee = watch('notSubmitIsee');
  useEffect(() => {
    if (watchNotSubmitIsee) {
      setValue('isee', null);
    }
  }, [watchNotSubmitIsee]);

  useEffect(() => {
    if (profile) {
      reset({
        ...profile.registry,
      });
    }
    // trigger()
    setIsStepLoading(false);
  }, [profile]);

  const submitForm = handleSubmit((data) => {
    // console.log(`${currentStep.name} form submitted: `, data)
    const parsedRegistry = registrySchema.parse(data);
    setProfile({ ...profile, registry: parsedRegistry });
    nextStep && navigate(`/application/form/${nextStep.path}`);
  });

  const [isStepComplete, setIsStepComplete] = useState<boolean>(false);

  useEffect(() => {
    console.log(formState.isValid);
    if (nextStep && formState.isValid) setIsStepComplete(true);
    else setIsStepComplete(false);
  }, [formState.isValid, nextStep]);

  // console.log(formState.errors)

  // useEffect(() => {
  //     console.log(errors)
  // }, [errors])

  return (
    <div ref={fieldRef} className="w-full flex flex-col">
      <FormStepManager
        profile={profile}
        steps={steps}
        prevStep={prevStep}
        currentStep={currentStep}
        nextStep={isStepComplete ? submitForm : undefined}
      />
      {isSteploading ? (
        <BlankLoader />
      ) : (
        <div className="w-full flex flex-col md:flex-row justify-between flex-wrap">
          <DescriptionBar
            requiredFields={13}
            formState={formState}
            nascondiIseeTraLeScelte_maPerche={
              (applicationAppealResidence &&
                [ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode)) ===
                true || applicationAppealResidence?.name?.includes('ROMA ') === true
            }
          />
          <div className="animate-fade-in-up md:animate-fade-in-right w-full md:w-8/12 flex flex-col  items-start md:pl-8 mt-8 md:mt-0">
            <h3 className="form-title">{t('Registry.a1')}</h3>
            <div className="flex flex-col md:flex-row w-full justify-between gap-4 ">
              <div className="w-full md:w-1/2 mb-4">
                <label className="form-label required" htmlFor="firstName">
                  {t('Registry.a2')}
                </label>
                <input
                  {...register('firstName', { required: true })}
                  defaultValue={undefined}
                  className={`form-input ${errors.firstName && 'error'}`}
                  id="firstName"
                  type="text"
                  placeholder={t('Registry.a2')}
                />
                <p className="input-error">{errors.firstName?.message}</p>
              </div>
              <div className="w-full md:w-1/2 mb-4">
                <label className="form-label required" htmlFor="lastName">
                  {t('Registry.a3')}
                </label>
                <input
                  {...register('lastName', { required: true })}
                  defaultValue={undefined}
                  className={`form-input ${errors.lastName && 'error'}`}
                  id="lastName"
                  type="text"
                  placeholder={t('Registry.a3')}
                />
                <p className="input-error">{errors.lastName?.message}</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between gap-4">
              {applicationAppealResidence?.mode &&
              [ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
                <div className="w-full md:w-1/2 mb-4">
                  <label className="form-label required" htmlFor="placeOfBirth">
                    {t('Registry.a4')}
                  </label>
                  <input
                    {...register('placeOfBirth', { required: true })}
                    defaultValue={undefined}
                    className={`form-input ${errors.placeOfBirth && 'error'}`}
                    id="placeOfBirth"
                    type="text"
                    placeholder={t('Registry.a4')}
                  />
                  <p className="input-error">{errors.placeOfBirth?.message}</p>
                </div>
              ) : null}
              <div className="w-full md:w-1/2 mb-4">
                <label className="form-label required" htmlFor="birthday">
                  {t('Registry.a5')}
                </label>
                {/* onChange={(e) => setEmail(e.target.value)} */}
                <Controller
                  control={control}
                  name="birthday"
                  render={({ field }) => (
                    <>
                      {/* <div className='hidden xl:block'> */}
                      <DatePicker
                        placeholderText={t('Registry.a6')}
                        dateFormat={'dd/MM/yyyy'}
                        // onChange={(date: Date) => { console.log(dayjs(date).utc().startOf('day').toDate()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                        // onChange={(date: Date) => { console.log(dayjs(date).format(), dayjs.utc(date).format()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                        onChange={(date: Date) => {
                          field.onChange(dayjs(date).startOf('day').toDate());
                        }}
                        selected={field.value}
                        withPortal
                        customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                        showMonthDropdown
                        showYearDropdown
                        minDate={dayjs().subtract(40, 'year').toDate()}
                        maxDate={dayjs().toDate()}
                        dropdownMode="select"
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        locale={'it-IT'}
                      />
                      {/* </div> */}
                      {/* <input className={`block xl:hidden form-input ${errors.birthday && 'error'}`} value={field.value && dayjs.utc(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; console.log(dayjs(value, 'YYYY-MM-DD').format(),dayjs.utc(value, 'YYYY-MM-DD').format()); field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                      {/* <input className={`block xl:hidden form-input ${errors.birthday && 'error'}`} value={field.value && dayjs.utc(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; field.onChange(value && value !== "" ? dayjs.utc(value, 'YYYY-MM-DD').startOf('day').toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                    </>
                  )}
                />
                <p className="input-error">{errors.birthday?.message}</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between gap-4">
              <div className="w-full md:w-1/2 mb-4">
                <label className="form-label required" htmlFor="citizenship">
                  {t('Registry.a7')}
                </label>
                <input
                  {...register('citizenship', { required: true })}
                  className={`form-input ${errors.citizenship && 'error'}`}
                  id="citizenship"
                  type="text"
                  placeholder={t('Registry.a7')}
                />
                <p className="input-error">{errors.citizenship?.message}</p>
              </div>
              {applicationAppealResidence?.mode &&
              ![ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
                <div className="w-full md:w-1/2 mb-4">
                  <label className="form-label" htmlFor="secondCitizenship">
                    {t('Registry.a8')}
                  </label>
                  <input
                    {...register('secondCitizenship', { required: true })}
                    className={`form-input ${errors.secondCitizenship && 'error'}`}
                    id="secondCitizenship"
                    type="text"
                    placeholder={t('Registry.a8')}
                  />
                  <p className="input-error">{errors.secondCitizenship?.message}</p>
                </div>
              ) : null}
            </div>
            {applicationAppealResidence?.mode &&
            ![ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) &&
            !applicationAppealResidence.name.includes('ROMA ') ? (
              <div className="flex flex-col md:flex-row w-full justify-between gap-4">
                <div className="w-full md:w-1/2 mb-4 flex flex-col">
                  <label className="form-label" htmlFor="notSubmitIsee">
                    {t('Registry.a9')}
                  </label>
                  <input
                    {...register('notSubmitIsee', {
                      setValueAs: (v) => (v ? !!v : null),
                    })}
                    className={`form-input w-4 h-4 mt-2 p-0 mb-4 ${errors.notSubmitIsee && 'error'}`}
                    id="notSubmitIsee"
                    type="checkbox"
                  />
                  <p className="text-xs text-black p-1">{t('Registry.a10')}</p>
                  <p className="input-error">{errors.isee?.message}</p>
                </div>

                {!watchNotSubmitIsee && (
                  <div className="w-full md:w-1/2 mb-4">
                    <label className="form-label" htmlFor="isee">
                      {t('Registry.a11')}
                    </label>
                    <input
                      {...register('isee', {
                        setValueAs: (v) => (v ? parseInt(v) : null),
                      })}
                      className={`form-input ${errors.isee && 'error'}`}
                      id="isee"
                      type="number"
                    />
                    <p className="text-xs text-black p-1">{t('Registry.a12')}</p>
                    <p className="input-error">{errors.isee?.message}</p>
                  </div>
                )}
              </div>
            ) : null}

            {applicationAppealResidence?.mode &&
            ![ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
              <div className="w-full mb-4">
                <label className="form-label required" htmlFor="fiscalCode">
                  {t('Registry.a13')}
                </label>
                <input
                  {...register('fiscalCode', { required: true })}
                  className={`form-input ${errors.fiscalCode && 'error'}`}
                  id="fiscalCode"
                  type="text"
                  placeholder={t('Registry.a13')}
                />
                <p className="input-error">{errors.fiscalCode?.message}</p>
              </div>
            ) : null}

            <h3 className="text-bluemazza font-bold text-lg mb-4">{t('Registry.a14')}</h3>
            <div className="flex flex-col md:flex-row w-full justify-between gap-4">
              <div className="w-full md:2/3 mb-4">
                <label className="form-label required" htmlFor="address">
                  {t('Registry.a15')}
                </label>
                <input
                  {...register('address.street', { required: true })}
                  className={`form-input ${errors.address?.street && 'error'}`}
                  id="address.street"
                  type="text"
                  placeholder={t('Registry.a15')}
                />
                <p className="input-error">{errors.address?.street?.message}</p>
              </div>
              <div className="w-full md:w-1/3 mb-4">
                <label className="form-label required" htmlFor="addressNumber">
                  {t('Registry.a16')}
                </label>
                <input
                  {...register('address.number', { required: true })}
                  className={`form-input ${errors.address?.number && 'error'}`}
                  id="address.number"
                  type="text"
                  placeholder={t('Registry.a16')}
                />
                <p className="input-error">{errors.address?.number?.message}</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-start gap-4">
              <div className="w-full md:w-1/4 mb-4">
                <label className="form-label required" htmlFor="zipCode">
                  {t('Registry.a17')}
                </label>
                <input
                  {...register('address.zipCode', { required: true })}
                  className={`form-input ${errors.address?.zipCode?.message && 'error'}`}
                  id="address.zipCode"
                  type="number"
                  placeholder={t('Registry.a17')}
                />
                <p className="input-error">{errors.address?.zipCode?.message}</p>
              </div>

              <div className="w-full md:w-1/4 mb-4">
                <label className="form-label required" htmlFor="city">
                  {t('Registry.a18')}
                </label>
                <input
                  {...register('address.city', { required: true })}
                  className={`form-input ${errors.address?.city && 'error'}`}
                  id="address.city"
                  type="text"
                  placeholder={t('Registry.a18')}
                />
                <p className="input-error">{errors.address?.city?.message}</p>
              </div>

              {applicationAppealResidence?.mode &&
              ![ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
                <div className="w-full md:w-1/4 mb-4">
                  <label className="form-label required" htmlFor="address.district">
                    {t('Registry.a19')}
                  </label>
                  <input
                    {...register('address.district', { required: true })}
                    className="form-input"
                    id="address.district"
                    type="text"
                    placeholder={t('Registry.a19')}
                  />
                  <p className="input-error">{errors.address?.district?.message}</p>
                </div>
              ) : null}

              <div className="w-full md:w-1/4 mb-4">
                <label className="form-label required" htmlFor="state">
                  {t('Registry.a20')}
                </label>
                <input
                  {...register('address.state', { required: true })}
                  className={`form-input ${errors.address?.state?.message && 'error'}`}
                  id="address.state"
                  type="text"
                  placeholder={t('Registry.a20')}
                />
                <p className="input-error">{errors.address?.state?.message}</p>
              </div>
            </div>
            <div className="flex flex-col md:flex-row w-full justify-between gap-4 ">
              <div className="w-full md:w-1/2 mb-4">
                <label className="form-label required" htmlFor="cellNumber">
                  {t('Registry.a21')}
                </label>
                <input
                  {...register('cellNumber', { required: true })}
                  className={`form-input ${formState.errors.cellNumber && 'error'}`}
                  id="cellNumber"
                  type="text"
                  placeholder={t('Registry.a21')}
                />
                <p className="input-error">{errors.cellNumber?.message}</p>
              </div>
              {applicationAppealResidence?.mode &&
              ![ResidenceMode.GRADUATES, ResidenceMode.INTERNATIONAL].includes(applicationAppealResidence.mode) ? (
                <div className="w-full md:w-1/2 mb-4">
                  <label className="form-label" htmlFor="telNumber">
                    {t('Registry.a22')}
                  </label>
                  <input
                    {...register('phoneNumber', { required: true })}
                    className={`form-input ${formState.errors.phoneNumber && 'error'}`}
                    id="telNumber"
                    type="text"
                    placeholder={t('Registry.a22')}
                  />
                  <p className="input-error">{errors.phoneNumber?.message}</p>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
      <button
        className="bluemazza-submit-button md:self-end"
        disabled={!isStepComplete}
        onClick={isStepComplete ? submitForm : undefined}
      >
        {t('Registry.a23')}
      </button>
    </div>
  );
}
