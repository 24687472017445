import { registerFn } from 'api/authApi';
import { Alert } from 'components/Shared/Alert/Alert';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { FetchStatus } from 'types';

interface RegistrationForm {
  email: string;
  password: string;
  passwordConfirmation: string;
  privacy: boolean;
}
const Registration: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [signupRequestStatus, setSignupRequestStatus] = useState<{
    status: FetchStatus;
    message?: string;
  }>({ status: FetchStatus.UNDEFINED });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<RegistrationForm>({ mode: 'all' });
  const watchPassword: string = watch('password', '');
  const onSubmit = handleSubmit((data) => runSignupRequest(data.email, data.password, data.passwordConfirmation));

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const [captcha, setCaptcha] = useState<string | null>(null);

  const isCaptchaOk = !process.env.REACT_APP_GTM_SITEKEY || !!captcha;

  const runSignupRequest = async (email: string, password: string, passwordConfirmation: string) => {
    setSignupRequestStatus({ status: FetchStatus.LOADING });
    try {
      await registerFn({ email, password, passwordConfirmation });
      setSignupRequestStatus({ status: FetchStatus.SUCCESS });
    } catch (e: any) {
      // console.error(e)
      setSignupRequestStatus({ status: FetchStatus.ERROR, message: e.message });
    }
  };

  const goToLogin = () => navigate('/auth/login');

  return (
    <div>
      <div className="flex flex-row justify-between align-middle mb-4">
        <h1 className="text-2xl text-white font-normal">{t('Registration.title')}</h1>
        <button
          onClick={goToLogin}
          className={`${FetchStatus.SUCCESS === signupRequestStatus.status && 'animate-bounce'} bluemazza-button`}
          type="button"
        >
          {t('Registration.login')}
        </button>
      </div>
      <form className="" onSubmit={onSubmit}>
        {[FetchStatus.ERROR, FetchStatus.SUCCESS].includes(signupRequestStatus.status) && (
          <div className="mb-4">
            <Alert
              status={signupRequestStatus.status === FetchStatus.ERROR ? 'ERROR' : 'SUCCESS'}
              title={
                signupRequestStatus.status === FetchStatus.ERROR
                  ? t('Registration.error.title')
                  : t('Registration.success.title')
              }
              description={
                signupRequestStatus.status === FetchStatus.ERROR
                  ? t('Registration.error.description')
                  : t('Registration.success.description')
              }
            />
          </div>
        )}
        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
            {t('Registration.email.label')}
          </label>
          <input
            {...register('email', {
              required: true,
              pattern: { value: /^\S+@\S+$/i, message: 'Email non valida' },
            })}
            className="auth-input"
            id="email"
            type="email"
            placeholder="Email"
          />
          <p className="input-error clear h-px">{errors.email?.message}</p>
        </div>
        <div className="w-full mb-4">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="password">
            {t('Registration.password.label')}
          </label>
          <div className="relative block mb-3 ">
            <input
              {...register('password', {
                required: true,
                minLength: {
                  value: 6,
                  message: t('Registration.password.error'),
                },
              })}
              className="auth-input"
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
            >
              {showPassword ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
            </button>
          </div>
          <p className="input-error clear h-px">{errors.password?.message}</p>
        </div>
        <div className="w-full mb-4 ">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="passwordConfirmation">
            {t('Registration.confirmPassword.label')}
          </label>
          <div className="relative block mb-3 ">
            <input
              {...register('passwordConfirmation', {
                required: true,
                validate: (value) => value === watchPassword || t('Registration.confirmPassword.error'),
              })}
              className="auth-input"
              id="passwordConfirmation"
              type={showPasswordConfirmation ? 'text' : 'password'}
              placeholder={t('Registration.confirmPassword.label')}
            />
            <button
              type="button"
              onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
              className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
            >
              {showPasswordConfirmation ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
            </button>
          </div>
          <p className="input-error clear h-px">{errors.passwordConfirmation?.message}</p>
        </div>
        <div className="w-full py-4">
          <div className="flex flex-row items-center">
            <input
              {...register('privacy', {
                required: true,
              })}
              className=" auth-input"
              id="privacy"
              type="checkbox"
              style={{ width: 'auto' }}
            />
            <a
              href={process.env.PUBLIC_URL + '/pdf/informativa candidati Application Mazza.pdf'}
              target="_blank"
              className="ml-4 block text-white text-sm font-bold hover:underline hover:underline-offset-4 cursor-pointer"
              rel="noreferrer"
            >
              {t('Registration.privacy.label')}
            </a>
          </div>
          <p className="input-error clear h-px">{errors.privacy?.message}</p>
        </div>
        {process.env.REACT_APP_GTM_SITEKEY && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_GTM_SITEKEY}
            onChange={setCaptcha}
            // size={"invisible"}
          />
        )}
        ,
        <div className="flex items-center justify-between w-full">
          <button
            disabled={
              !isCaptchaOk ||
              !isValid ||
              [FetchStatus.LOADING, FetchStatus.SUCCESS].includes(signupRequestStatus.status)
            }
            className="bluemazza-button"
            type="submit"
          >
            {signupRequestStatus.status === FetchStatus.LOADING ? (
              <ScaleLoader color="white" />
            ) : (
              t('Registration.submit')
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Registration;
