import { UserSessionModel } from "types"
import Cookies from "universal-cookie"

const cookies = new Cookies()

export const getUserInCookie = () => cookies.get<UserSessionModel | undefined>("userSession")

export const setUserInCookie = (user: UserSessionModel) => cookies.set("userSession", user, { path: "/" })

export const removeUserInCookie = () => cookies.remove("userSession", { path: "/" })
