import { refreshTokenFn } from 'api/authApi';
import axios from 'axios'
import { UserSessionModel } from 'types';

import Cookies from 'universal-cookie'

const cookies = new Cookies();

const useRefreshToken = () => {


    const refresh = async (): Promise<string | undefined> => {

        const userSession: UserSessionModel | undefined = cookies.get('userSession')
        if (userSession) {
            try {
                const refreshResponse = await refreshTokenFn(userSession.refreshToken)
                return refreshResponse.accessToken
            }
            catch (e: any) {
                // console.error(e)
                return undefined
            }


        }
        return undefined
    }

    return { refresh }
}

export default useRefreshToken

export function useToken() {

    const getLocalRefreshToken = () => {
        const session = cookies.get('session');
        return session?.refreshToken;
    };
    const getLocalAccessToken = () => {
        const session = cookies.get('session');
        return session?.accessToken;
    };
    const updateLocalAccessToken = (token: string) => {
        const session = cookies.get('session');
        if (session)
            session.accessToken = token;
        cookies.set(
            'session',
            session,
            { path: '/' }
            // {path: '/', httpOnly: true}
        );
    };

    const removeTokens = () => {
        cookies.remove('session')
    };

    return {
        getLocalRefreshToken,
        getLocalAccessToken,
        updateLocalAccessToken,
        removeTokens

    }


}