import Modal from 'components/Shared/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';

interface IEditApplicationDialog {
  isOpen: boolean;
  onClose: () => void;
  editApplication: () => void;
}
const EditApplicationDialog: React.FC<IEditApplicationDialog> = ({ isOpen, onClose, editApplication }) => {
  const { t } = useTranslation();
  const modalTitle = t('EditApplicationDialog.title');
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose} type="warning">
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <p className="text-black font-medium text-md">{t('EditApplicationDialog.description1')}</p>
        <p className="text-green-500 font-bold text-md">{t('EditApplicationDialog.description2')}</p>
        <p className="text-black font-bold text-md">{t('EditApplicationDialog.description3')}</p>

        <div className="w-full mt-4 flex flex-row items-center justify-between">
          <button onClick={onClose} className="bluemazza-button outline">
            {t('EditApplicationDialog.cancel')}
          </button>
          <button onClick={editApplication} className="bluemazza-button">
            <span className="flex flex-row items-center gap-2">
              <FaEdit />
              <p>{t('EditApplicationDialog.edit')}</p>
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditApplicationDialog;
