import axios from 'axios';
import { IAppeal, IResidence } from 'types/api/residences';

export async function getResidencesFn(): Promise<IResidence[]> {
  const response = await axios.get('/api/residences?actived=true');
  return response.data;
}

export async function getAppealsFn(): Promise<IAppeal[]> {
  const response = await axios.get('/api/residences/appeals');
  return response.data;
}
