import React, { useContext, useState, useEffect, createContext } from 'react';

import axios from 'axios';

import sendNotification from 'utils/notifications';
import { DecodedToken, FetchStatus, UserSessionModel } from '../types';

import { useNavigate } from 'react-router-dom';
import { FaviconLoader } from 'components/Shared/Spinner/FaviconLoader';

import useRefreshToken from 'hooks/useRefreshToken';
import { getUserInCookie, removeUserInCookie, setUserInCookie } from 'utils/cookies';
import { loginFn, refreshTokenFn } from 'api/authApi';
import jwtDecode from 'jwt-decode';

type AppProps = {
  children?: React.ReactNode;
};

export type AuthenticationContextProps = {
  userSession: UserSessionModel | undefined;
  setUserSession: React.Dispatch<React.SetStateAction<UserSessionModel | undefined>>;
  userLoginStatus: FetchStatus;
  authInterceptors: { req: number; res: number } | undefined;
  setUserLogout: (sessionExpired: boolean) => void;
  runLoginRequest: (email: string, password: string) => void;
  loginRequestStatus: { status: FetchStatus; message?: string };
};

export const AuthenticationContext = createContext<AuthenticationContextProps>({
  userSession: undefined,
  setUserSession: () => undefined,
  userLoginStatus: FetchStatus.UNDEFINED,
  authInterceptors: undefined,
  setUserLogout: (sessionExpired: boolean) => {},
  runLoginRequest: (email: string, password: string) => {},
  loginRequestStatus: { status: FetchStatus.UNDEFINED },
});

export function AuthenticationContextProvider({ children }: AppProps) {
  const navigate = useNavigate();
  const [userSession, setUserSession] = useState<UserSessionModel | undefined>(undefined);
  const [userLoginStatus, setUserLoginStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const [authInterceptors, setAuthInterceptors] = useState<{ req: number; res: number } | undefined>(undefined);

  const [loginRequestStatus, setLoginRequestStatus] = useState<{
    status: FetchStatus;
    message?: string;
  }>({ status: FetchStatus.UNDEFINED });

  const { refresh } = useRefreshToken();

  async function isAuthenticated() {
    const userSessionInCookie = getUserInCookie();
    if (userSessionInCookie) {
      try {
        setUserLoginStatus(FetchStatus.LOADING);
        const refreshResponse = await refreshTokenFn(userSessionInCookie.refreshToken);
        userSessionInCookie.accessToken = refreshResponse.accessToken;
        setUserInCookie(userSessionInCookie);
        setUserSession(userSessionInCookie);
        setUserLoginStatus(FetchStatus.SUCCESS);
      } catch (e: any) {
        setUserLoginStatus(FetchStatus.ERROR);
        setUserLogout();
      }
    } else {
      // console.log("token not found")
      setUserLoginStatus(FetchStatus.ERROR);
      // navigate('/auth/login')
    }
  }

  async function setUserLogout(sessionExpired: boolean = false) {
    const userSessionInCookie = getUserInCookie();
    if (userSessionInCookie) removeUserInCookie();

    setUserSession(undefined);
    setUserLoginStatus(FetchStatus.ERROR);
    navigate('/auth/login');
    if (sessionExpired) sendNotification('Disconnesso', 'La tua sessione è scaduta', 'warning', true, 9000);
  }

  async function runLoginRequest(email: string, password: string) {
    setLoginRequestStatus({ status: FetchStatus.LOADING });
    try {
      const loginResponse = await loginFn({ email, password });
      const decodedToken = jwtDecode<DecodedToken>(loginResponse.accessToken);
      if (decodedToken) {
        setUserSession({ ...decodedToken, ...loginResponse });
        setUserInCookie({ ...decodedToken, ...loginResponse });
      }
      setLoginRequestStatus({ status: FetchStatus.SUCCESS });
    } catch (e: any) {
      // console.log(e.response.data)
      let message = undefined;
      if (e.response.data && typeof e.response.data === 'string') message = e.response.data;
      if (message === 'Please verify your account') message = 'Devi prima verificare il tuo account';
      if (message === 'Invalid email or password!') message = 'Credenziali errate';
      setLoginRequestStatus({ status: FetchStatus.ERROR, message: message });

      sendNotification(message || 'Credenziali non valide.', '', 'error', true, 4500);
    }
  }

  useEffect(() => {
    isAuthenticated();
  }, []);

  useEffect(() => {
    if (userSession) {
      const reqInterceptor = axios.interceptors.request.use(async (req) => {
        // console.log("req interceptor ran")
        if (!req.headers) req.headers = {};
        if (!req.headers['Authorization'])
          //Already setted? Or avoid retry
          req.headers = {
            ...req.headers,
            Authorization: `Bearer ${userSession?.accessToken}`,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0',
          };

        return req;
      });
      // console.log("reqInterceptor created")
      const resInterceptor = axios.interceptors.response.use(
        async (res) => res,
        async (error) => {
          // console.log('res interceptor ran')
          // console.log(userSession)
          let prevRequest = error.config;
          if (error.response?.status == 403 && !prevRequest.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

            return axios(prevRequest);
          }
          return Promise.reject(error);
        }
      );
      // console.log("resInterceptor created")

      setAuthInterceptors({ req: reqInterceptor, res: resInterceptor });
      return () => {
        console.log('Interceptors ejected');
        axios.interceptors.request.eject(reqInterceptor);
        axios.interceptors.response.eject(resInterceptor);
      };
    }
  }, [userSession]);

  return (
    <AuthenticationContext.Provider
      // Add required values to the value prop within an object (my preference)
      value={{
        userSession,
        setUserSession,
        userLoginStatus,
        authInterceptors,
        setUserLogout,
        runLoginRequest,
        loginRequestStatus,
      }}
    >
      {[FetchStatus.LOADING, FetchStatus.UNDEFINED].includes(userLoginStatus) ? <FaviconLoader /> : children}
    </AuthenticationContext.Provider>
  );
}

// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useAuthentication() {
  const context = useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
