import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import Footer from './components/footer';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import { AuthenticationContextProvider } from 'context/authContext';
import { BrowserRouter } from 'react-router-dom';
import { ApplicationContextProvider } from 'context/applicationContext';
import { ResidencesContextProvider } from 'context/residencesContext';
import { registerLocale } from 'react-datepicker';
import { it } from 'date-fns/locale';
import CookieConsent, { Cookies } from 'react-cookie-consent';

import TagManager from 'react-gtm-module';
import NotificationContainer from 'components/NotificationContainer';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import { SwitchLanguage } from 'components/I18n/SwitchLanguage';

const tagManagerArgs = {
  gtmId: 'GTM-NBKR2HN',
};

if (process.env.REACT_APP_ENVIRONMENT === 'prod') TagManager.initialize(tagManagerArgs);

registerLocale('it-IT', it);

function Index() {
  return (
    <BrowserRouter>
      <NotificationContainer />
      <AuthenticationContextProvider>
        <ResidencesContextProvider>
          <ApplicationContextProvider>
            <SwitchLanguage />
            <Router />
            <Footer />
          </ApplicationContextProvider>
        </ResidencesContextProvider>
      </AuthenticationContextProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
    <CookieConsent
      location="bottom"
      buttonText="Accetta"
      cookieName="dm_cookies"
      style={{ background: '#0064a9' }}
      buttonStyle={{ color: '#0064a9', backgroundColor: '#fff', borderRadius: 8 }}
      expires={150}
    >
      Nel nostro sito utilizziamo sia cookie tecnici necessari per il suo funzionamento, sia cookie e altri strumenti di
      tracciamento facoltativi che potrai attivare solo con il tuo consenso, maggiori informazioni{' '}
      <a
        href={process.env.PUBLIC_URL + '/pdf/informativa-candicati.pdf'}
        target="_blank"
        className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
        rel="noreferrer"
      >
        qui
      </a>
      .
    </CookieConsent>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
