import { verifyUserFn } from 'api/authApi';
import { Alert } from 'components/Shared/Alert/Alert';
import Modal from 'components/Shared/Modal/Modal';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { FetchStatus } from 'types';

interface IVerifyAccountDialog {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  verificationCode: string;
}
const VerifyAccountDialog: React.FC<IVerifyAccountDialog> = ({ isOpen, onClose, userId, verificationCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [status, setStatus] = useState<{
    status: FetchStatus;
    message?: string;
  }>({ status: FetchStatus.UNDEFINED });

  const runVerificationRequest = async (userId: string, verificationCode: string) => {
    setStatus({ status: FetchStatus.LOADING });
    try {
      await verifyUserFn({ id: userId, verificationCode });
      setStatus({ status: FetchStatus.SUCCESS });
    } catch (e: any) {
      setStatus({
        status: FetchStatus.ERROR,
        message: e.message,
      });
    }
  };

  useEffect(() => {
    runVerificationRequest(userId, verificationCode);
  }, [userId, verificationCode]);

  const onLoginClick = () => {
    onClose();
    navigate('/auth/login');
  };
  const modalTitle = t('VerifyAccountDialog.title');
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <div className="p-4">
        {status.status === FetchStatus.LOADING ? (
          <Alert status="INFO" title="Verifica in corso" component={<ScaleLoader />} />
        ) : status.status === FetchStatus.SUCCESS ? (
          <Alert
            status="SUCCESS"
            title={t('VerifyAccountDialog.success.title')}
            description={t('VerifyAccountDialog.success.description')}
          />
        ) : (
          <Alert
            status="ERROR"
            title={t('VerifyAccountDialog.error.title')}
            description={t('VerifyAccountDialog.error.description')}
          />
        )}
      </div>

      <div className="p-4 flex flex-col items-center">
        <button
          disabled={status.status === FetchStatus.LOADING}
          onClick={onLoginClick}
          className="transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
        >
          {t('VerifyAccountDialog.login')}
        </button>
      </div>
    </Modal>
  );
};

export default VerifyAccountDialog;
