import { object, string, TypeOf, z, date, number, boolean, infer, nativeEnum } from 'zod';
import mongoose from 'mongoose';

import {
  FamilyMemberType,
  MaritalStatusType,
  HighSchoolType,
  DegreeSystemType,
  ProfessionType,
  ProfessionTypeRelationship,
  ProfessionContractType,
  ProfessionSector,
  ContractualLevel,
  CompanyType,
} from 'types/index';

import CodiceFiscale from 'codice-fiscale-js';
import dayjs from 'utils/dayjs';
import { ResidenceMode } from './api/residences';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  // if (issue.code === z.ZodIssueCode.invalid_type) {
  //   if (issue.expected === "string") {
  //     return { message: "bad type!" };
  //   }
  // }
  // if (issue.code === z.ZodIssueCode.custom) {
  //   return { message: `less-than-${(issue.params || {}).minimum}` };
  // }
  if (issue.code === z.ZodIssueCode.invalid_arguments) {
    return { message: 'Obbligatorio' };
  }
  if (issue.code === z.ZodIssueCode.invalid_date) {
    return { message: 'Data non valida' };
  }
  if (issue.code === z.ZodIssueCode.too_small) {
    return { message: `Il minimo è ${issue.minimum}` };
  }
  if (issue.code === z.ZodIssueCode.too_big) {
    return { message: `Il massimo è ${issue.maximum}` };
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

//Accept Dates, stringify Dates and string dates in format DD/MM/YYYY
export const stringDate = z.preprocess((input) => {
  // console.log(typeof input)
  if (input instanceof Date) return input;
  if (typeof input == 'string') {
    console.log('string', input);
    const isDateStringObject = new Date(input);
    //Check if is type Date but stringified
    console.log(isDateStringObject);
    if (isDateStringObject.toString() !== 'Invalid Date') {
      console.log(isDateStringObject);
      return isDateStringObject;
    }

    //Check if is valid date DD/MM/YYYY
    const parsedDate = dayjs(input, 'DD/MM/YYYY');
    const parsedDateToDate = parsedDate.toDate();
    // console.log(input, parsedDateToDate)
    return parsedDateToDate;
  }
  if (typeof input == 'number') return dayjs(input).toDate();
}, z.date());

export const familyCompositionSchema = object({
  notSubmitIsee: boolean().default(false),
  type: nativeEnum(FamilyMemberType, {
    required_error: 'type is required in familyComposition',
  }),
  completeName: string({
    required_error: 'completeName is required in familyComposition',
  }).min(1, {
    message: 'Il campo è obbligatorio',
  }),
  birthday: stringDate,
  maritalStatus: nativeEnum(MaritalStatusType, {
    required_error: 'maritalStatus is required in familyComposition',
  }),
  eduQualification: string({
    required_error: 'eduQualification is required in familyComposition',
  }).min(1, {
    message: 'Il campo è obbligatorio',
  }),
  isPublicEmployee: boolean().optional().default(false),
  profession: nativeEnum(ProfessionType, {
    required_error: 'profession is required in familyComposition',
  }),
  startActivityYear: number().max(new Date().getFullYear()).nullish().default(null),
  endActivityYear: number().max(new Date().getFullYear()).nullish().default(null),
  professionTypeRelationship: nativeEnum(ProfessionTypeRelationship, {
    required_error: 'professionTypeRelationship is required in familyComposition',
  })
    .nullish()
    .default(null),
  professionContractType: nativeEnum(ProfessionContractType, {
    required_error: 'professionContractType is required in familyComposition',
  })
    .nullish()
    .default(null),
  professionSector: nativeEnum(ProfessionSector, {
    required_error: 'professionSector is required in familyComposition',
  })
    .nullish()
    .default(null),

  contractualLevel: nativeEnum(ContractualLevel, {
    required_error: 'contractualLevel is required in familyComposition',
  })
    .nullish()
    .default(null),

  companyType: nativeEnum(CompanyType, {
    required_error: 'companyType is required in familyComposition',
  })
    .nullish()
    .default(null),

  numMembers: number().min(0).nullish().default(null),
  numEmployees: number().min(0).nullish().default(null),
  companyName: string().nullish().default(null),

  extraordinaryExpenses: boolean().nullish().default(null),
}).superRefine((data, ctx) => {
  // if (
  //   !data.notSubmitIsee &&
  //   data.profession &&
  //   data.profession === ProfessionType.OCCUPATO &&
  //   !data.startActivityYear
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'startActivityYear is required in familyComposition',
  //     path: ['startActivityYear'],
  //   });
  // } else if (
  //   !data.notSubmitIsee &&
  //   data.profession &&
  //   [ProfessionType.INOCCUPATO_ATRO, ProfessionType.PENSIONATO].includes(data.profession) &&
  //   !data.endActivityYear
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'endActivityYear is required in familyComposition',
  //     path: ['endActivityYear'],
  //   });
  // } else if (
  //   !data.notSubmitIsee &&
  //   data.professionTypeRelationship &&
  //   [ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE, ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA].includes(
  //     data.professionTypeRelationship
  //   ) &&
  //   !data.companyType
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'companyType is required in familyComposition',
  //     path: ['companyType'],
  //   });
  // }
  // if (
  //   !data.notSubmitIsee &&
  //   data.professionTypeRelationship &&
  //   [ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE, ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA].includes(
  //     data.professionTypeRelationship
  //   ) &&
  //   !data.companyName
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'companyName is required in familyComposition',
  //     path: ['companyName'],
  //   });
  // }
  // if (
  //   !data.notSubmitIsee &&
  //   data.professionTypeRelationship &&
  //   [ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE, ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA].includes(
  //     data.professionTypeRelationship
  //   ) &&
  //   (data.numEmployees === null || data.numEmployees === undefined)
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'numEmployees is required in familyComposition',
  //     path: ['numEmployees'],
  //   });
  // }
  // if (
  //   !data.notSubmitIsee &&
  //   data.professionTypeRelationship &&
  //   [ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE, ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA].includes(
  //     data.professionTypeRelationship
  //   ) &&
  //   (data.numMembers === null || data.numMembers === undefined)
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'numMembers is required in familyComposition',
  //     path: ['numMembers'],
  //   });
  // }
  // if (
  //   !data.notSubmitIsee &&
  //   data.professionTypeRelationship &&
  //   ![ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE, ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA].includes(
  //     data.professionTypeRelationship
  //   ) &&
  //   !data.contractualLevel
  // ) {
  //   ctx.addIssue({
  //     code: z.ZodIssueCode.custom,
  //     message: 'contractualLevel is required in familyComposition',
  //     path: ['contractualLevel'],
  //   });
  // }
});

export const registrySchema = object({
  firstName: string({
    required_error: 'Obbligatorio',
  }).min(1, {
    message: 'Obbligatorio',
  }),
  lastName: string({
    required_error: 'Obbligatorio',
  }).min(1, {
    message: 'Obbligatorio',
  }),
  fiscalCode: string({
    required_error: 'Obbligatorio',
  })
    .refine(
      (cf) => {
        try {
          new CodiceFiscale(cf);
          return true;
        } catch (e: any) {
          // console.log(e)
          return false;
        }
      },
      {
        message: 'Codice fiscale non valido',
      }
    )
    .nullish(),
  birthday: stringDate.refine((val) => val, {
    message: 'Obbligatorio',
  }),
  placeOfBirth: string({
    required_error: 'Obbligatorio',
  })
    .nullable()
    .default(null),
  citizenship: string({
    required_error: 'Obbligatorio',
  }).min(1, {
    message: 'Obbligatorio',
  }),
  secondCitizenship: string().nullish().default(null),
  notSubmitIsee: boolean().nullable().default(null),
  isee: number().min(0).nullish().default(null),
  address: object({
    street: string({
      required_error: 'Obbligatorio',
    }).min(1, {
      message: 'Obbligatorio',
    }),
    state: string({
      required_error: 'Obbligatorio',
    }).min(1, {
      message: 'Obbligatorio',
    }),
    district: string({
      required_error: 'Obbligatorio',
    })
      .min(1, {
        message: 'Obbligatorio',
      })
      .nullish(),
    city: string({
      required_error: 'Obbligatorio',
    }).min(1, {
      message: 'Obbligatorio',
    }),
    zipCode: string({
      required_error: 'Obbligatorio',
    }).min(1, {
      message: 'Obbligatorio',
    }),
    number: string({
      required_error: 'Obbligatorio',
    }).min(1, {
      message: 'Obbligatorio',
    }),
  }),
  phoneNumber: string().nullish().default(null),
  cellNumber: string({
    required_error: 'Obbligatorio',
  }).min(1, {
    message: 'Obbligatorio',
  }),
});

export const curriculumSchema = object({
  highSchool: object({
    name: string()
      .min(1, {
        message: 'Obbligatorio',
      })
      .nullish(),
    type: nativeEnum(HighSchoolType).nullish(),
    city: string()
      .min(1, {
        message: 'Obbligatorio',
      })
      .nullish(),
    finalScore: number().min(60).max(100).optional().nullish().default(null),
    courseOfStudy: string().optional().default(''),
    hasPraise: boolean().optional().default(false),
    averageScore: number().min(6).max(10).nullish().default(null),
  }).optional(),
  university: object({
    firstUniEnrollment: object({
      university: string().min(1, {
        message: 'Obbligatorio',
      }),
      degree: string().min(1, {
        message: 'Obbligatorio',
      }),
      degreeYear: number().min(1, {
        message: 'Obbligatorio',
      }),
      degreeSystem: nativeEnum(DegreeSystemType),
    }).optional(),
    alreadyEnrolled: object({
      hasBachelor: object({
        university: string().min(1, {
          message: 'Obbligatorio',
        }),
        degree: string().min(1, {
          message: 'Obbligatorio',
        }),
        degreeScore: number().min(80).max(110),
        degreeHasPraise: boolean().optional().default(false),
        degreeDate: stringDate,
      }).optional(),
      academicYears: object({
        current: object({
          university: string().min(1, {
            message: 'Obbligatorio',
          }),
          degree: string().min(1, {
            message: 'Obbligatorio',
          }),
          degreeYear: number().min(1, {
            message: 'Obbligatorio',
          }),
          degreeSystem: nativeEnum(DegreeSystemType),
          creditsEarned: number().min(1, {
            message: 'Obbligatorio',
          }),
          avgExamsScore: number().min(18).max(30),
        }),
        next: object({
          university: string().min(1, {
            message: 'Obbligatorio',
          }),
          degree: string().min(1, {
            message: 'Obbligatorio',
          }),
          degreeYear: number().min(1, {
            message: 'Obbligatorio',
          }),
          degreeSystem: nativeEnum(DegreeSystemType),
        }),
      }),
    }).optional(),
    international: object({
      residence: string()
        .min(1, {
          message: 'Obbligatorio',
        })
        .nullish(),
      degreeYear: number().min(1, {
        message: 'Obbligatorio',
      }),
      degreeSystem: nativeEnum(DegreeSystemType),
      cycle: string()
        .min(1, {
          message: 'Obbligatorio',
        })
        .nullish(),
      from: stringDate
        .refine((val) => val, {
          message: 'Obbligatorio',
        })
        .nullish(),
      to: stringDate
        .refine((val) => val, {
          message: 'Obbligatorio',
        })
        .nullish(),
    }).optional(),
    graduate: object({
      university: string().min(1, {
        message: 'Obbligatorio',
      }),
      degree: string().min(1, {
        message: 'Obbligatorio',
      }),
      degreeScore: number().min(80).max(110),
      degreeYear: number()
        .min(1, {
          message: 'Obbligatorio',
        })
        .nullish(),
      degreeSystem: nativeEnum(DegreeSystemType).nullish(),
      residence: string()
        .min(1, {
          message: 'Obbligatorio',
        })
        .nullish(),
      cycle: string()
        .min(1, {
          message: 'Obbligatorio',
        })
        .nullish(),
    }).optional(),
  }).refine((data) => {
    if (data.alreadyEnrolled === undefined || data.firstUniEnrollment === undefined) {
      return true;
    }
    if (!(data.firstUniEnrollment || data.alreadyEnrolled)) return false;
    else return true;
  }),
  highSchoolCertificate: boolean().default(false),
  degreeCertificate: boolean().default(false),
});

export const questionAnswerSchema = object({
  question_id: string().refine((val) => mongoose.Types.ObjectId.isValid(val), {
    message: 'One ore more question_id are not valid',
  }),
  response: string().min(5, { message: 'Sono richiesti almeno 5 caratteri' }),
});

export const userProfileSchema = object({
  registry: registrySchema.strict().optional(),
  curriculum: curriculumSchema.strict().optional(),
  familyComposition: familyCompositionSchema.array().optional(),
  questions: questionAnswerSchema.array().optional(),
}).partial();
export const getUserProfileSchema = object({
  registry: registrySchema.strict().optional(),
  curriculum: curriculumSchema.optional(),
  familyComposition: familyCompositionSchema.array().optional(),
  questions: questionAnswerSchema.array().optional(),
}).partial();

export const updateUserProfileSchema = userProfileSchema; //.strict()

export type updateUserProfileType = z.infer<typeof updateUserProfileSchema>;
export type getUserProfileType = z.infer<typeof getUserProfileSchema>;
export type questionAnswerType = z.infer<typeof questionAnswerSchema>;
export type familyCompositionType = z.infer<typeof familyCompositionSchema>;
export type curriculumType = z.infer<typeof curriculumSchema>;
export type registryType = z.infer<typeof registrySchema>;
