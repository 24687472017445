import moment from 'moment';

export default function Footer() {
  return (
    <div className=" px-4 mt-8 bg-bluemazza animate-fade-in-up ">
      <div className="w-full py-8 flex flex-col md:flex-row justify-between flex-wrap align-middle ">
        <div>
          <p className="text-white text-md text-medium">Collegio Universitario Don Nicola Mazza</p>
          <p className="text-white text-md text-medium">via dei Savonarola, 176</p>
          <p className="text-white text-md text-medium">Padova, Italia</p>
          <p className="text-white text-md text-medium">T. 049 8734411</p>
        </div>
        <a
          className="height-auto w-full md:w-4/12 lg:w-2/12 flex justify-center md:justify-end "
          href="https://www.collegiomazza.it"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/logo_white.png"
            alt="logo_collegiomazza"
            className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
          />
        </a>
      </div>
      <div className="pb-2  text-white text-md text-medium block">
        <div className="hidden md:flex w-full flex-row justify-between">
          <p>
            © {moment().format('YYYY')} Collegio Don Nicola Mazza - Tutti i diritti riservati - C.F. 00748590288 |{' '}
            <a
              href={process.env.PUBLIC_URL + '/pdf/informativa candidati Application Mazza.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer transition-colors ease duration-300 hover:text-orange-500"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href={process.env.PUBLIC_URL + '/pdf/cookie-policy.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer transition-colors ease duration-300 hover:text-orange-500"
            >
              Cookie Policy
            </a>
          </p>
          <a
            href="https://www.remedia.it/"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer transition-colors ease duration-300 hover:text-orange-500"
          >
            Made with ♥ by reMedia
          </a>
        </div>
        <div className="flex md:hidden w-full flex-col items-center justify-between">
          <p>
            © {moment().format('YYYY')} Collegio Don Nicola Mazza - Tutti i diritti riservati - C.F. 00748590288 |{' '}
            <a
              href={process.env.PUBLIC_URL + '/pdf/informativa candidati Application Mazza.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer transition-colors ease duration-300 hover:text-orange-500"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href={process.env.PUBLIC_URL + '/pdf/cookie-policy.pdf'}
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer transition-colors ease duration-300 hover:text-orange-500"
            >
              Cookie Policy
            </a>
          </p>
          <a
            href="https://www.remedia.it/"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor-pointer transition-colors ease duration-300 hover:text-orange-500"
          >
            Made with ♥ by reMedia
          </a>
        </div>
      </div>
    </div>
  );
}
