import dayjs from "dayjs";

// var AdvancedFormat = require('dayjs/plugin/relativeTime')
import relativeTime from 'dayjs/plugin/relativeTime' // ES 2015
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime) // use plugin
dayjs.extend(utc)

dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
    relativeTime: {
        future: "tra %s",
        past: "%s fa",
        s: 'qualche secondo',
        m: "un minuto",
        mm: "%d minuti",
        h: "un ora",
        hh: "%d ore",
        d: "un giorno",
        dd: "%d giorni",
        M: "un mese",
        MM: "%d mesi",
        y: "un anno",
        yy: "%d anni"
    }
})

export default dayjs
// export default dayjs