import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IAppeal, IResidence } from 'types/api/residences';

interface IApplicationPresent {
  residence: IResidence;
  appeal: IAppeal;
}
const ApplicationPresent: React.FC<IApplicationPresent> = ({ residence, appeal }) => {
  const { t } = useTranslation();
  return (
    <div className="animate-fade-in-up flex flex-col items-center gap-4  mt-16 shadow border bg-white rounded p-4">
      <h1 className="text-green-500 px-4 py-2 rounded-full font-bold text-3xl text-center">
        {t('ApplicationPresent.title')}
      </h1>
      <h2 className="text-black font-bold text-xl text-center">{t('ApplicationPresent.description1')}</h2>
      <h3 className="text-bluemazza font-bold text-lg text-center">
        {residence.name} - {dayjs(appeal.date).format('DD/MM/YYYY')}
      </h3>
      <h3 className="font-medium text-lg underline text-center">{t('ApplicationPresent.description2')}</h3>
    </div>
  );
};
export default ApplicationPresent;
