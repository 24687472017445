import axios from 'axios';
import { updateUserProfileType } from 'types/zod';

export async function getUserProfileFn(): Promise<updateUserProfileType> {
  const response = await axios.get('/api/users/user/profile');
  return response.data;
}

export async function deleteUserProfileFn(): Promise<void> {
  await axios.delete('/api/users/user/profile');
}

export async function updateUserProfileFn(body: updateUserProfileType): Promise<updateUserProfileType> {
  const response = await axios.put('/api/users/user/profile', body);
  return response.data;
}
