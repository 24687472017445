import React from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { FetchStatus } from 'types';
import { ScaleLoader } from 'react-spinners';

import { FaChevronDown, FaTrashAlt } from 'react-icons/fa';
import Modal from 'components/Shared/Modal/Modal';
import { useTranslation } from 'react-i18next';

interface IHelpDialog {
  isOpen: boolean;
  onClose: () => void;
  deleteProfileRequest: () => void;
  deleteProfileRequestStatus: FetchStatus;
  deleteApplicationRequest: () => void;
  deleteApplicationRequestStatus: FetchStatus;
}
const HelpDialog: React.FC<IHelpDialog> = ({
  isOpen,
  onClose,
  deleteProfileRequest,
  deleteProfileRequestStatus,
  deleteApplicationRequest,
  deleteApplicationRequestStatus,
}) => {
  const { t } = useTranslation();
  const modalTitle = t('HelpDialog.title');
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <DeleteUserProfileDisclosure
          deleteProfileRequest={deleteProfileRequest}
          deleteProfileRequestStatus={deleteProfileRequestStatus}
        />
        <h5 className="text-sm underline">
          {t('HelpDialog.description1')}
          <a
            href="mailto:info@collegiomazza.it"
            className="text-bluemazza hover:text-lightbluemazza transition transitions-ease duration-300"
          >
            info@collegiomazza.it
          </a>
          {t('HelpDialog.description2')}
        </h5>
      </div>
    </Modal>
  );
};

const DeleteUserProfileDisclosure = ({
  deleteProfileRequest,
  deleteProfileRequestStatus,
}: {
  deleteProfileRequest: () => void;
  deleteProfileRequestStatus: FetchStatus;
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full mx-auto bg-white rounded">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`transition-colors  ease-in-out duration-300 flex justify-between w-full px-4 py-2 text-sm font-medium text-left shadow border border-bluemazza ${
                open ? 'bg-bluemazza text-white rounded-t' : 'rounded'
              } hover:bg-bluemazza hover:text-white`}
            >
              {t('HelpDialog.description3')}
              <FaChevronDown className={`${open ? ' transform rotate-180' : ''} w-5 h-5`} />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-black border border-bluemazza rounded-b">
                <div className=" flex flex-col justify-between items-start gap-2 w-full">
                  <h3>{t('HelpDialog.description4')}</h3>
                  <ul className="list-disc text-black font-medium text-md pl-4">
                    <li>
                      <span className="font-bold ">{t('HelpDialog.description5')}</span>: {t('HelpDialog.description6')}
                    </li>
                    <ul className="list-disc text-black font-medium text-md pl-4">
                      <li>
                        {t('HelpDialog.description7')}
                        <span className="italic">{t('HelpDialog.description8')}</span>
                        {t('HelpDialog.description9')}
                        <span className="italic">{t('HelpDialog.description10')}</span>
                      </li>
                      <li>{t('HelpDialog.description11')}</li>
                    </ul>
                  </ul>
                  <button
                    disabled={deleteProfileRequestStatus === FetchStatus.LOADING}
                    onClick={deleteProfileRequest}
                    className="self-center  transition-colors ease-in-out duration-300  bg-red-500 hover:bg-red-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    {deleteProfileRequestStatus === FetchStatus.LOADING ? (
                      <ScaleLoader color="white" />
                    ) : (
                      <span className="flex flex-row justify-center items-center gap-2">
                        <FaTrashAlt />
                        <p>{t('HelpDialog.delete')}</p>
                      </span>
                    )}
                  </button>
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default HelpDialog;
