import { zodResolver } from '@hookform/resolvers/zod';
import Modal from 'components/Shared/Modal/Modal';
import { Controller, useForm } from 'react-hook-form';
import { FamilyMemberType, MaritalStatusType, ProfessionType } from 'types';
import { familyCompositionSchema, familyCompositionType } from 'types/zod';

import DatePicker from 'react-datepicker';
import { CustomDatePickerInput } from 'components/reactDatepicker';
import dayjs from 'dayjs';
import React from 'react';
import { FaSave } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface IFamilyMemberDialog {
  isOpen: boolean;
  onClose: () => void;
  addMember: (data: familyCompositionType) => void;
}
const FamilyMemberDialog: React.FC<IFamilyMemberDialog> = ({ isOpen, onClose, addMember }) => {
  const { register, control, reset, handleSubmit, formState } = useForm<familyCompositionType>({
    resolver: zodResolver(familyCompositionSchema),
    mode: 'all',
  });
  const { t } = useTranslation();
  const { errors, isValid } = formState;
  const onSubmit = handleSubmit((data) => {
    // console.log(data)
    addMember(data);
    reset();
    onClose();
  });

  // console.log(errors)

  const modalTitle = t('FamilyMemberDialog.title');
  return (
    <>
      <Modal title={modalTitle} isOpen={isOpen} onClose={onClose} showCloseIcon={true}>
        <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
          <form className="flex flex-col items-start gap-4 w-full" onSubmit={onSubmit}>
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="w-full">
                <label className="form-label required" htmlFor="type">
                  {t('FamilyMemberDialog.type.label')}
                </label>
                <select {...register('type')} className={`form-input ${errors.type && 'error'}`} id="type">
                  {Object.keys(FamilyMemberType).map((key, idx) => (
                    <option key={idx} value={FamilyMemberType[key as keyof typeof FamilyMemberType]}>
                      {FamilyMemberType[key as keyof typeof FamilyMemberType]}
                    </option>
                  ))}
                </select>
                <p className="input-error">{errors.type?.message}</p>
              </div>
              <div className="w-full">
                <label className="form-label required" htmlFor="maritalStatus">
                  {t('FamilyMemberDialog.maritalStatus.label')}
                </label>
                <select
                  {...register('maritalStatus')}
                  className={`form-input ${errors.maritalStatus && 'error'}`}
                  id="maritalStatus"
                >
                  {Object.keys(MaritalStatusType).map((key, idx) => (
                    <option key={idx} value={MaritalStatusType[key as keyof typeof MaritalStatusType]}>
                      {MaritalStatusType[key as keyof typeof MaritalStatusType]}
                    </option>
                  ))}
                </select>
                <p className="input-error">{errors.maritalStatus?.message}</p>
              </div>

              <div className="w-full">
                <label className="form-label required" htmlFor="completeName">
                  {t('FamilyMemberDialog.name.label')}
                </label>
                <input
                  {...register('completeName', { required: true })}
                  className={`form-input ${errors.completeName && 'error'}`}
                  id="completeName"
                  type="text"
                  placeholder={t('FamilyMemberDialog.name.placeholder')}
                />
                <p className="input-error">{errors.completeName?.message}</p>
              </div>
              <div className="w-full">
                <label className="form-label required" htmlFor="birthday">
                  {t('FamilyMemberDialog.birthday.label')}
                </label>
                <Controller
                  control={control}
                  name={`birthday`}
                  render={({ field }) => (
                    <>
                      {/* <div className='hidden xl:block'> */}
                      <DatePicker
                        placeholderText={t('FamilyMemberDialog.birthday.placeholder')}
                        dateFormat={'dd/MM/yyyy'}
                        // onChange={(date: Date) => { console.log(dayjs(date).utc().startOf('day').toDate()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                        onChange={(date: Date) => {
                          field.onChange(dayjs(date).startOf('day').toDate());
                        }}
                        selected={field.value}
                        customInput={React.createElement(React.forwardRef(CustomDatePickerInput))}
                        showMonthDropdown
                        showYearDropdown
                        // minDate={dayjs().subtract(40, 'year').toDate()}
                        maxDate={dayjs().toDate()}
                        dropdownMode="select"
                        locale={'it-IT'}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                      />
                      {/* </div> */}
                      {/* <input className={`block xl:hidden form-input  ${errors.birthday && 'error'}`} value={field.value && dayjs(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; console.log(dayjs(value,'YYYY-MM-DD').format('YYYY-MM-DD')); console.log(dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate()); field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                      {/* <input className={`block xl:hidden form-input  ${errors.birthday && 'error'}`} value={field.value && dayjs(field.value).utc().startOf('day').format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').utc().startOf('day').toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                    </>
                  )}
                />
                <p className="input-error">{errors.birthday?.message}</p>
              </div>

              <div className="w-full">
                <label className="form-label required" htmlFor="eduQualification">
                  {t('FamilyMemberDialog.eduQualification.label')}
                </label>
                <select
                  {...register(`eduQualification`, { required: true })}
                  className="form-input"
                  id={`eduQualification`}
                  placeholder="Seleziona"
                >
                  <option value="Nessuno">{t('FamilyCompositionPerson.a1')}</option>
                  <option value="Licenza elementare">{t('FamilyCompositionPerson.a2')}</option>
                  <option value="Licenza media">{t('FamilyCompositionPerson.a3')}</option>
                  <option value="Diploma professionale">{t('FamilyCompositionPerson.a4')}</option>
                  <option value="Diploma di maturità">{t('FamilyCompositionPerson.a5')}</option>
                  <option value="Laurea">{t('FamilyCompositionPerson.a6')}</option>
                  <option value="Dottorato/master">{t('FamilyCompositionPerson.a7')}</option>
                </select>

                <p className="input-error">{errors.eduQualification?.message}</p>
              </div>
              <div className="w-full">
                <label className="form-label required" htmlFor="profession">
                  {t('FamilyMemberDialog.profession.label')}
                </label>
                <select {...register(`profession`)} className="form-input" id={`profession`}>
                  {Object.keys(ProfessionType).map((key, idx) => (
                    <option key={idx} value={ProfessionType[key as keyof typeof ProfessionType]}>
                      {ProfessionType[key as keyof typeof ProfessionType]}
                    </option>
                  ))}
                </select>
                <p className="input-error">{errors.profession?.message}</p>
              </div>
              {/* <div className="w-full flex flex-row items-center">
              <label className="block text-lightbluemazza text-sm font-bold mb-0" htmlFor="isPublicEmployee">
                {t('FamilyMemberDialog.isPublicEmployee.label')}
              </label>
              <input
                {...register('isPublicEmployee', { required: true })}
                className="w-auto form-input ml-4"
                id="isPublicEmployee"
                type="checkbox"
              />
            </div> */}
            </div>
            <button
              disabled={!isValid}
              className=" w-6/12 self-center disabled:opacity-50 disabled:bg-blue-500 transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              <span className="flex flex-row justify-center items-center gap-2">
                <FaSave />
                <p>{t('FamilyMemberDialog.submit')}</p>
              </span>
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default FamilyMemberDialog;
