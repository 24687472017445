import React, { useContext, useState, useEffect, createContext, useMemo } from 'react';

import sendNotification from 'utils/notifications';
import { FetchStatus } from 'types';

import { IAppeal, IResidence } from 'types/api/residences';
import { IApplication, IApplicationStatus } from 'types/api/applications';
import { useNavigate } from 'react-router-dom';
import { FaviconLoader } from 'components/Shared/Spinner/FaviconLoader';
import { useAuthentication } from './authContext';
import { updateUserProfileSchema, updateUserProfileType, getUserProfileSchema } from 'types/zod';

import dayjs from 'dayjs';
import { useResidences } from './residencesContext';
import { createApplicationFn, deleteApplicationFn, getApplicationFn, updateApplicationFn } from 'api/applicationsApi';
import { deleteUserProfileFn, getUserProfileFn } from 'api/userApi';
import EditApplicationDialog from 'components/Application/EditApplicationDialog/EditApplicationDialog';

type AppProps = {
  children?: React.ReactNode;
};

export type ApplicationContextProps = {
  application: IApplication | undefined;
  setApplication: React.Dispatch<React.SetStateAction<IApplication | undefined>>;
  applicationAppeal: IAppeal | undefined;
  applicationAppealResidence: IResidence | undefined;
  applicationFetchStatus: FetchStatus;
  userProfile: updateUserProfileType | undefined;
  setUserProfile: React.Dispatch<React.SetStateAction<updateUserProfileType | undefined>>;
  userProfileFetchStatus: FetchStatus;
  openEditApplicationConfirm: () => void;
  createApplicationRequest: (appeal_id: string, promoCode?: string) => void;
  updateApplicationAppealRequest: (appeal_id: string, promoCode?: string) => void;
  createUpdateApplicationAppealFetchStatus: FetchStatus;
  deleteUserProfile: () => void;
  deleteProfileStatus: FetchStatus;
  deleteApplication: () => void;
  deleteApplicationStatus: FetchStatus;
};

export const ApplicationContext = createContext<ApplicationContextProps>({
  application: undefined,
  setApplication: () => undefined,
  applicationAppeal: undefined,
  applicationAppealResidence: undefined,
  applicationFetchStatus: FetchStatus.UNDEFINED,
  userProfile: undefined,
  setUserProfile: () => undefined,
  userProfileFetchStatus: FetchStatus.UNDEFINED,
  openEditApplicationConfirm: () => undefined,
  createApplicationRequest: (appeal_id: string, promoCode?: string) => undefined,
  updateApplicationAppealRequest: (appeal_id: string, promoCode?: string) => undefined,
  createUpdateApplicationAppealFetchStatus: FetchStatus.UNDEFINED,
  deleteUserProfile: () => undefined,
  deleteProfileStatus: FetchStatus.UNDEFINED,
  deleteApplication: () => undefined,
  deleteApplicationStatus: FetchStatus.UNDEFINED,
});

export function ApplicationContextProvider({ children }: AppProps) {
  const navigate = useNavigate();

  const { userSession, authInterceptors } = useAuthentication();
  const { residences, appeals } = useResidences();

  const [application, setApplication] = useState<IApplication | undefined>(undefined);
  const [applicationFetchStatus, setApplicationFetchStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const [userProfile, setUserProfile] = useState<updateUserProfileType | undefined>(undefined);
  const [userProfileFetchStatus, setUserProfileFetchStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const [isEditApplicationConfirmOpen, setIsEditApplicationConfirmOpen] = useState<boolean>(false);
  const [cancelApplicationSendStatus, setCancelApplicationSendStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const [deleteApplicationStatus, setDeleteApplicationStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);
  const [deleteProfileStatus, setDeleteProfileStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const [createUpdateApplicationAppealFetchStatus, setCreateUpdateApplicationAppealFetchStatus] = useState<FetchStatus>(
    FetchStatus.UNDEFINED
  );

  const applicationAppeal = useMemo(
    () => appeals.find((appeal) => appeal._id === application?.appeal_id),
    [application, appeals]
  );
  const applicationAppealResidence = useMemo(
    () => residences.find((residence) => residence._id === applicationAppeal?.residence_id),
    [applicationAppeal, residences]
  );

  //Automatically redirect based on application status(called in fetchApplication)
  const redirectBasedOnApplication = (fetchedApplication: IApplication | undefined) => {
    if (fetchedApplication) {
      const finalStates = [
        IApplicationStatus.APPLICATION_COMPLETE,
        IApplicationStatus.APPLICATION_ACCEPTED,
        IApplicationStatus.APPLICATION_REJECTED,
        IApplicationStatus.PRESENT,
        IApplicationStatus.WITHDRAWN,
        IApplicationStatus.WINNER,
        IApplicationStatus.LOSER,
      ];
      if (finalStates.includes(fetchedApplication.status)) return navigate('/application/conclusion');

      const relatedAppeal = appeals.find((appeal) => appeal._id === fetchedApplication.appeal_id);
      if (!relatedAppeal)
        //appeal not found -> May be deleted via backoffice
        return navigate('/application/create');
      if (dayjs() > dayjs(relatedAppeal.expiryDate)) return navigate('/application/create');

      if (fetchedApplication.status === IApplicationStatus.APPLICATION_CREATED) return navigate('/application/form');
    } else navigate('/application/create');

    // if (location.pathname === '/application/create')
    //     navigate('form')
  };

  const editApplication = async () => {
    setCancelApplicationSendStatus(FetchStatus.LOADING);
    try {
      const updatedApplication = await updateApplicationFn({
        status: IApplicationStatus.APPLICATION_CREATED,
      });
      setApplication(updatedApplication);
      setCancelApplicationSendStatus(FetchStatus.SUCCESS);
      sendNotification('Invio annullato. Puoi modificare la domanda', '', 'success', true, 5000);
      return navigate('/application/form');
    } catch (e: any) {
      // console.error(e)
      sendNotification("Impossibile annullare l'invio della domanda", '', 'error', true, 5000);
      setCancelApplicationSendStatus(FetchStatus.ERROR);
      return null;
    }
  };

  const deleteApplication = async () => {
    setDeleteApplicationStatus(FetchStatus.LOADING);
    try {
      await deleteApplicationFn();
      setApplication(undefined);
      setDeleteApplicationStatus(FetchStatus.SUCCESS);
      sendNotification('Candidatura eliminata, puoi crearne una nuova', '', 'success', true, 5000);
      navigate('/application/create');
    } catch (e: any) {
      // console.error(e)
      sendNotification('Impossibile rimuovere la candidatura', '', 'error', true, 5000);
      setDeleteApplicationStatus(FetchStatus.ERROR);
    }
  };

  const deleteUserProfile = async () => {
    setDeleteProfileStatus(FetchStatus.LOADING);
    try {
      await deleteUserProfileFn();
      setUserProfile(undefined);
      setDeleteProfileStatus(FetchStatus.SUCCESS);
      sendNotification('Profilo utente rimosso', '', 'success', true, 5000);
      navigate('/application/form');
    } catch (e: any) {
      // console.error(e)
      sendNotification('Impossibile rimuovere il profilo utente', '', 'error', true, 5000);
      setDeleteProfileStatus(FetchStatus.ERROR);
    }
  };

  const fetchUserProfile = async (): Promise<updateUserProfileType | null> => {
    try {
      setUserProfileFetchStatus(FetchStatus.LOADING);
      const fetchedUserProfile = await getUserProfileFn();
      console.log(fetchedUserProfile);
      const parsedProfile = getUserProfileSchema.parse(fetchedUserProfile);
      setUserProfile(parsedProfile);
      setUserProfileFetchStatus(FetchStatus.SUCCESS);
      return parsedProfile;
    } catch (e: any) {
      console.error(e);
      setUserProfileFetchStatus(FetchStatus.ERROR);
      return null;
    }
  };

  async function fetchApplication() {
    try {
      setApplicationFetchStatus(FetchStatus.LOADING);
      const fetchedApplication = await getApplicationFn();
      setApplication(fetchedApplication);
      setApplicationFetchStatus(FetchStatus.SUCCESS);
      redirectBasedOnApplication(fetchedApplication);
    } catch (e: any) {
      // console.error(e, typeof(e))
      setApplicationFetchStatus(FetchStatus.ERROR);
      navigate('/application/create');
    }
  }

  const createApplicationRequest = async (appeal_id: string, promoCode?: string) => {
    try {
      setCreateUpdateApplicationAppealFetchStatus(FetchStatus.LOADING);
      const createdApplication = await createApplicationFn({
        appeal_id: appeal_id,
        ...(promoCode && { promoCode: promoCode }),
      });
      setApplication(createdApplication);
      setCreateUpdateApplicationAppealFetchStatus(FetchStatus.SUCCESS);
      sendNotification('Candidatura creata con successo', '', 'success', true, 5000);
      return navigate('/application/form');
    } catch (e: any) {
      // console.error(e, typeof(e))
      setCreateUpdateApplicationAppealFetchStatus(FetchStatus.ERROR);
      sendNotification('Impossibile creare la tua candidatura', '', 'error', true, 5000);
    }
  };

  const updateApplicationAppealRequest = async (appeal_id: string, promoCode?: string) => {
    try {
      setCreateUpdateApplicationAppealFetchStatus(FetchStatus.LOADING);
      const updatedApplication = await updateApplicationFn({
        appeal_id: appeal_id,
        ...(promoCode && { promoCode: promoCode }),
      });
      setApplication(updatedApplication);
      setCreateUpdateApplicationAppealFetchStatus(FetchStatus.SUCCESS);
      sendNotification('Aggiornamento candidatura eseguito', 'Dati aggiornati correttamente', 'success', true, 5000);
      return navigate('/application/form');
    } catch (e: any) {
      // console.error(e)
      sendNotification('Aggiornamento candidatura fallito', 'Aggiornamento candidatura eseguito', 'error', true, 5000);
      setCreateUpdateApplicationAppealFetchStatus(FetchStatus.ERROR);
    }
  };

  useEffect(() => {
    if (authInterceptors) {
      fetchApplication();
      fetchUserProfile();
    } else {
      setApplicationFetchStatus(FetchStatus.ERROR);
      setUserProfileFetchStatus(FetchStatus.ERROR);
    }
  }, [authInterceptors]);

  return (
    <ApplicationContext.Provider
      // Add required values to the value prop within an object (my preference)
      value={{
        application,
        setApplication,
        applicationAppeal,
        applicationAppealResidence,
        applicationFetchStatus,
        userProfile,
        setUserProfile,
        userProfileFetchStatus,
        openEditApplicationConfirm: () => setIsEditApplicationConfirmOpen(true),
        createApplicationRequest,
        updateApplicationAppealRequest,
        createUpdateApplicationAppealFetchStatus,
        deleteApplication,
        deleteApplicationStatus,
        deleteUserProfile,
        deleteProfileStatus,
      }}
    >
      <EditApplicationDialog
        isOpen={isEditApplicationConfirmOpen}
        onClose={() => setIsEditApplicationConfirmOpen(false)}
        editApplication={() => {
          editApplication();
          setIsEditApplicationConfirmOpen(false);
        }}
      />
      {[FetchStatus.LOADING, FetchStatus.UNDEFINED].some((status) =>
        [applicationFetchStatus, userProfileFetchStatus].includes(status)
      ) ? (
        <FaviconLoader />
      ) : (
        children
      )}
    </ApplicationContext.Provider>
  );
}

// Create a hook to use the APIContext, this is a Kent C. Dodds pattern
export function useApplication() {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
