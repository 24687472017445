import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { IAppeal, IResidence } from 'types/api/residences';

interface IApplicationRejected {
  residence: IResidence;
  appeal: IAppeal;
}
const ApplicationRejected: React.FC<IApplicationRejected> = ({ residence, appeal }) => {
  const { t } = useTranslation();
  return (
    <div className="animate-fade-in-up flex flex-col items-center gap-4  mt-16 shadow border bg-white rounded p-4">
      <h1 className="bg-red-500 px-4 py-2 text-white rounded-full font-bold text-3xl text-center">
        {t('ApplicationRejected.title')}
      </h1>
      <h2 className="text-black font-bold text-xl text-center">{t('ApplicationRejected.description1')}</h2>
      <h3 className="text-bluemazza font-bold text-lg text-center">
        {residence.name} - {dayjs(appeal.date).format('DD/MM/YYYY')}
      </h3>
      <h2 className="text-red-500 text-xl font-bold">{t('ApplicationRejected.description2')}</h2>
      <h3 className="text-bluemazza font-medium text-lg underline text-center">
        {t('ApplicationRejected.description3')}
      </h3>
    </div>
  );
};
export default ApplicationRejected;
