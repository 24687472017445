import { Routes, Route, Navigate } from 'react-router-dom';
import Auth from 'pages/Auth/Auth';
import CreateApplication from 'pages/application/create';
import Form from 'pages/application/form/index';
import ConfirmSendApplication from './pages/application/confirm';
import ApplicationUploads from 'pages/application/uploads';
import ApplicationConclusion from 'pages/application/conclusion';

import { useAuthentication } from 'context/authContext';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  authenticationPath: string;
  outlet: JSX.Element;
};

function ProtectedRoute({ isAuthenticated, authenticationPath, outlet }: ProtectedRouteProps) {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
}

const Router = () => {
  const { userSession } = useAuthentication();

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: !!userSession,
    authenticationPath: '/auth/login',
  };

  return (
    <>
      {/* <Navigation /> */}
      <div className="px-6 xl:px-8 " style={{ minHeight: '75vh' }}>
        <Routes>
          {/* <Route index element={<Home />} /> */}
          <Route path="auth/registration" element={<Auth />} />
          <Route path="auth/login" element={<Auth />} />
          <Route path="auth/verify/:userId/:verificationCode" element={<Auth />} />
          <Route path="auth/resetPassword/:userId/:resetPasswordCode" element={<Auth />} />
          <Route
            path="application/create"
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CreateApplication />} />}
          />
          <Route
            path="application/form/:step"
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Form />} />}
          />
          <Route
            path="application/form"
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Form />} />}
          />
          {/*<Route
            path="application/send/confirm"
            element={
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                outlet={<ConfirmSendApplication />}
              />
            }
          /> */}
          <Route
            path="application/uploads"
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ApplicationUploads />} />}
          />
          <Route
            path="application/conclusion"
            element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ApplicationConclusion />} />}
          />
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
      </div>
    </>
  );
};

export default Router;
