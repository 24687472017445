export function FaviconLoader() {
  return (
    <div className=" h-screen w-full flex items-center justify-center text-bluemazza ">
      <img
        alt="loader"
        src="/images/pittogramma.png"
        className=" transition ease duration-300 cursor-pointer object-contain hover:scale-110"
      />
    </div>
  );
}
