import { resetPasswordFn } from 'api/authApi';
import { Alert } from 'components/Shared/Alert/Alert';
import Modal from 'components/Shared/Modal/Modal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { FetchStatus } from 'types';

interface IResetPasswordDialog {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  resetPasswordCode: string;
}
const ResetPasswordDialog: React.FC<IResetPasswordDialog> = ({ isOpen, onClose, userId, resetPasswordCode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPasswordRequestStatus, setResetPasswordRequestStatus] = useState<{
    status: FetchStatus;
    message?: string;
  }>({ status: FetchStatus.UNDEFINED });

  interface ResetPasswordForm {
    password: string;
    passwordConfirmation: string;
  }
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ResetPasswordForm>({
    mode: 'all',
  });

  const watchPassword: string = watch('password', '');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const runResetPasswordRequest = async (data: ResetPasswordForm) => {
    const { password, passwordConfirmation } = data;
    if (userId && resetPasswordCode) {
      setResetPasswordRequestStatus({ status: FetchStatus.LOADING });
      try {
        await resetPasswordFn({
          id: userId,
          passwordResetCode: resetPasswordCode,
          password,
          passwordConfirmation,
        });
        setResetPasswordRequestStatus({ status: FetchStatus.SUCCESS });
      } catch (e: any) {
        // console.error(e)
        let message = undefined;
        if (e.response.data && typeof e.response.data === 'string') message = e.response.data;
        if (message === 'Please verify your account') message = t('ResetPasswordDialog.error.verify');
        setResetPasswordRequestStatus({
          status: FetchStatus.ERROR,
          message: message,
        });
      }
    }
  };

  const onLoginClick = () => {
    onClose();
    navigate('/auth/login');
  };
  const modalTitle = 'Aggiorna la tua password';
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        {[FetchStatus.SUCCESS, FetchStatus.ERROR].includes(resetPasswordRequestStatus.status) ? (
          <div className="flex w-full flex-col items-center gap-4">
            <Alert
              status={resetPasswordRequestStatus.status === FetchStatus.SUCCESS ? 'SUCCESS' : 'ERROR'}
              title={
                resetPasswordRequestStatus.status === FetchStatus.SUCCESS
                  ? t('ResetPasswordDialog.success.title')
                  : t('ResetPasswordDialog.error.title')
              }
              description={
                resetPasswordRequestStatus.status === FetchStatus.SUCCESS
                  ? t('ResetPasswordDialog.success.description')
                  : t('ResetPasswordDialog.error.description')
              }
            />
            <button
              onClick={onLoginClick}
              className="transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              {t('ResetPasswordDialog.login')}
            </button>
          </div>
        ) : (
          <form
            className="w-full flex flex-col justify-between items-start gap-2"
            onSubmit={handleSubmit(runResetPasswordRequest)}
          >
            <p className="text-sm text-gray-500">Inserisci e conferma la tua nuova password</p>
            <div className="w-full">
              <label className="form-label" htmlFor="password">
                {t('ResetPasswordDialog.email.label')}
              </label>

              <div className="relative block mb-3 ">
                <input
                  {...register('password', {
                    required: true,
                    minLength: {
                      value: 6,
                      message: t('ResetPasswordDialog.email.error'),
                    },
                  })}
                  className="form-input"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
                >
                  {showPassword ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
                </button>
              </div>
              {errors.password && <p className="input-error">{errors.password.message}</p>}
            </div>
            <div className="w-full">
              <label className="form-label" htmlFor="passwordConfirmation">
                {t('ResetPasswordDialog.confirmPassword.label')}
              </label>

              <div className="relative block mb-3 ">
                <input
                  {...register('passwordConfirmation', {
                    required: true,
                    validate: (value) => value === watchPassword || t('ResetPasswordDialog.confirmPassword.error'),
                  })}
                  className="form-input"
                  id="passwordConfirmation"
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  placeholder="Conferma Password"
                />
                <button
                  type="button"
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
                >
                  {showPasswordConfirmation ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
                </button>
              </div>
              {errors.passwordConfirmation && <p className="input-error">{errors.passwordConfirmation.message}</p>}
            </div>
            <button
              disabled={resetPasswordRequestStatus.status === FetchStatus.LOADING || !isValid}
              className="disabled:opacity-50 disabled:bg-blue-500 self-center transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {resetPasswordRequestStatus.status === FetchStatus.LOADING ? (
                <ScaleLoader color="white" />
              ) : (
                t('ResetPasswordDialog.confirmPassword.label')
              )}
            </button>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ResetPasswordDialog;
