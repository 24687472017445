import { FC, createElement, forwardRef, useEffect, useState } from 'react';
import { Control, Controller, FieldValues, UseFormRegister, UseFormResetField, useWatch } from 'react-hook-form';
import { FaTrashAlt } from 'react-icons/fa';
import {
  CompanyType,
  ContractualLevel,
  FamilyMemberType,
  MaritalStatusType,
  ProfessionContractType,
  ProfessionSector,
  ProfessionType,
  ProfessionTypeRelationship,
} from 'types';
import DatePicker from 'react-datepicker';
import { CustomDatePickerInput } from 'components/reactDatepicker';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

type FamilyCompositionPersonProps = {
  index: number;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  resetField: UseFormResetField<FieldValues>;
  errors: Record<string, any>;
  onRemove: () => void;
  showJobsInfo: boolean;
};
const FamilyCompositionPerson: FC<FamilyCompositionPersonProps> = ({
  register,
  index,
  control,
  resetField,
  errors,
  onRemove,
  showJobsInfo,
}) => {
  const { t } = useTranslation();
  const watchProfession = useWatch({ control, name: `familyComposition.${index}.profession` });
  const watchProfessionTypeRelationship = useWatch({
    control,
    name: `familyComposition.${index}.professionTypeRelationship`,
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    resetField(`familyComposition.${index}.notSubmitIsee`, { defaultValue: showJobsInfo });
  }, [showJobsInfo]);

  useEffect(() => {
    if (
      watchProfession &&
      [ProfessionType.OCCUPATO, ProfessionType.INOCCUPATO_ATRO, ProfessionType.PENSIONATO].includes(watchProfession)
    ) {
      if (ProfessionType.OCCUPATO === watchProfession) {
        resetField(`familyComposition.${index}.endActivityYear`, { defaultValue: null });
      } else {
        resetField(`familyComposition.${index}.startActivityYear`, { defaultValue: null });
      }
      if (!show) {
        setShow(true);
      }
    } else {
      if (show) {
        // svuota dati precedenti
        resetField(`familyComposition.${index}.startActivityYear`, { defaultValue: null });
        resetField(`familyComposition.${index}.endActivityYear`, { defaultValue: null });
        resetField(`familyComposition.${index}.professionTypeRelationship`, { defaultValue: null });
        resetField(`familyComposition.${index}.professionContractType`, { defaultValue: null });
        resetField(`familyComposition.${index}.professionSector`, { defaultValue: null });
        resetField(`familyComposition.${index}.contractualLevel`, { defaultValue: null });
        resetField(`familyComposition.${index}.companyType`, { defaultValue: null });
        resetField(`familyComposition.${index}.numMembers`, { defaultValue: null });
        resetField(`familyComposition.${index}.numEmployees`, { defaultValue: null });
        resetField(`familyComposition.${index}.companyName`, { defaultValue: null });
        resetField(`familyComposition.${index}.extraordinaryExpenses`, { defaultValue: null });
        setShow(false);
      }
    }
  }, [watchProfession]);

  useEffect(() => {
    if (
      watchProfessionTypeRelationship &&
      [ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE, ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA].includes(
        watchProfessionTypeRelationship
      )
    ) {
      resetField(`familyComposition.${index}.contractualLevel`, { defaultValue: null });
    } else if (watchProfessionTypeRelationship) {
      resetField(`familyComposition.${index}.companyType`, { defaultValue: null });
      resetField(`familyComposition.${index}.numMembers`, { defaultValue: null });
      resetField(`familyComposition.${index}.numEmployees`, { defaultValue: null });
      resetField(`familyComposition.${index}.companyName`, { defaultValue: null });
    }
  }, [watchProfessionTypeRelationship]);

  return (
    <div className="w-full p-4 shadow rounded bg-white border">
      <input type="hidden" {...register(`familyComposition.${index}.notSubmitIsee`)} />
      <div className="flex flex-row items-center justify-between w-full mb-4">
        <div className="w-8/12">
          <label className="form-label" htmlFor="type">
            {t('FamilyCompositionPerson.type.label')}
          </label>
          <select
            {...register(`familyComposition.${index}.type`)}
            className="form-input"
            id={`familyComposition.${index}.type`}
          >
            {Object.keys(FamilyMemberType).map((key, index) => (
              <option key={index} value={FamilyMemberType[key as keyof typeof FamilyMemberType]}>
                {FamilyMemberType[key as keyof typeof FamilyMemberType]}
              </option>
            ))}
          </select>
        </div>

        <button onClick={onRemove} className="w-2/12 red-button self-start">
          <span className="flex flex-row items-center justify-center gap-2">
            <FaTrashAlt />
            <p className="hidden md:block"> {t('FamilyComposition.a8')}</p>
          </span>
        </button>
      </div>

      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="w-full">
          <label className="form-label" htmlFor={`familyComposition.${index}.completeName`}>
            {t('FamilyCompositionPerson.name.label')}
          </label>
          {/* onChange={(e) => setEmail(e.target.value)} */}
          <input
            {...register(`familyComposition.${index}.completeName`, {
              required: true,
            })}
            className="form-input"
            id={`familyComposition.${index}.completeName`}
            type="text"
            placeholder={t('FamilyCompositionPerson.name.placeholder')}
          />
        </div>
        <div className="w-full">
          <label className="form-label" htmlFor={`familyComposition.${index}.birthday`}>
            {t('FamilyCompositionPerson.birthday.label')}
          </label>
          <Controller
            control={control}
            name={`familyComposition.${index}.birthday`}
            render={({ field }) => (
              <>
                {/* <div className='hidden xl:block'> */}
                <DatePicker
                  placeholderText={t('FamilyCompositionPerson.birthday.placeholder')}
                  dateFormat={'dd/MM/yyyy'}
                  // onChange={(date: Date) => { console.log(dayjs(date).utc().startOf('day').toDate()); field.onChange(dayjs(date).utc().startOf('day').toDate()) }}
                  onChange={(date: Date) => {
                    field.onChange(dayjs(date).startOf('day').toDate());
                  }}
                  selected={field.value}
                  withPortal
                  customInput={createElement(forwardRef(CustomDatePickerInput))}
                  showMonthDropdown
                  showYearDropdown
                  // minDate={dayjs().subtract(40, 'year').toDate()}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  maxDate={dayjs().toDate()}
                  dropdownMode="select"
                  locale={'it-IT'}
                />
                {/* </div> */}
                {/* <input className={`block xl:hidden form-input  ${errors.familyComposition?.[index]?.birthday && 'error'}`} value={field.value && dayjs(field.value).format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; console.log(dayjs(value,'YYYY-MM-DD').format('YYYY-MM-DD')); console.log(dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate()); field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').startOf('day').utc(true).toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
                {/* <input className={`block xl:hidden form-input  ${errors.familyComposition?.[index]?.birthday && 'error'}`} value={field.value && dayjs(field.value).utc().startOf('day').format('YYYY-MM-DD')} onChange={(e) => { const value = e.target.value; field.onChange(value && value !== "" ? dayjs(value, 'YYYY-MM-DD').utc().startOf('day').toDate() : undefined) }} id="birthday" type="date" placeholder="DD/MM/YYYY" onKeyDown={(e) => e.preventDefault()} /> */}
              </>
            )}
          />

          <p className="input-error">{errors.familyComposition?.[index]?.birthday.message}</p>
        </div>
        <div className="w-full">
          <label className="form-label" htmlFor={`familyComposition.${index}.maritalStatus`}>
            {t('FamilyCompositionPerson.maritalStatus.label')}
          </label>
          <select
            {...register(`familyComposition.${index}.maritalStatus`)}
            className="form-input"
            id={`familyComposition.${index}.maritalStatus`}
          >
            {Object.keys(MaritalStatusType).map((key, index) => (
              <option key={index} value={MaritalStatusType[key as keyof typeof MaritalStatusType]}>
                {MaritalStatusType[key as keyof typeof MaritalStatusType]}
              </option>
            ))}
          </select>
        </div>
        <div className="w-full">
          <label className="form-label" htmlFor={`familyComposition.${index}.eduQualification`}>
            {t('FamilyCompositionPerson.eduQualification.label')}
          </label>
          <select
            {...register(`familyComposition.${index}.eduQualification`, { required: true })}
            className="form-input"
            id={`familyComposition.${index}.eduQualification`}
          >
            <option value="Nessuno">{t('FamilyCompositionPerson.a1')}</option>
            <option value="Licenza elementare">{t('FamilyCompositionPerson.a2')}</option>
            <option value="Licenza media">{t('FamilyCompositionPerson.a3')}</option>
            <option value="Diploma professionale">{t('FamilyCompositionPerson.a4')}</option>
            <option value="Diploma di maturità">{t('FamilyCompositionPerson.a5')}</option>
            <option value="Laurea">{t('FamilyCompositionPerson.a6')}</option>
            <option value="Dottorato/master">{t('FamilyCompositionPerson.a7')}</option>
          </select>
        </div>
        <div className="w-full">
          <label className="form-label" htmlFor={`familyComposition.${index}.profession`}>
            {t('FamilyCompositionPerson.profession.label')}
          </label>
          <select
            {...register(`familyComposition.${index}.profession`)}
            className="form-input"
            id={`familyComposition.${index}.profession`}
          >
            {Object.keys(ProfessionType).map((key, index) => (
              <option key={index} value={ProfessionType[key as keyof typeof ProfessionType]}>
                {ProfessionType[key as keyof typeof ProfessionType]}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="w-full flex flex-row md:flex-col items-start gap-2">
          <label
            className="block text-lightbluemazza text-sm font-bold mb-0"
            htmlFor={`familyComposition.${index}.isPublicEmployee`}
          >
            {t('FamilyCompositionPerson.isPublicEmployee.label')}
          </label>
          <input
            {...register(`familyComposition.${index}.isPublicEmployee`)}
            className="form-input w-4 h-4 mt-2.5 p-0 mb-4"
            id={`familyComposition.${index}.isPublicEmployee`}
            type="checkbox"
          />
        </div> */}
      </div>

      {showJobsInfo && show && (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4">
          {watchProfession === ProfessionType.OCCUPATO && (
            <div className="w-full">
              <label className="form-label" htmlFor={`familyComposition.${index}.startActivityYear`}>
                {t('FamilyCompositionPerson.startActivityYear.label')}
              </label>
              {/* onChange={(e) => setEmail(e.target.value)} */}
              <input
                {...register(`familyComposition.${index}.startActivityYear`, {
                  setValueAs: (v) => (v ? parseInt(v) : null),
                })}
                type="number"
                className="form-input"
                id={`familyComposition.${index}.startActivityYear`}
                placeholder={t('FamilyCompositionPerson.startActivityYear.placeholder')}
              />
            </div>
          )}
          {(watchProfession === ProfessionType.PENSIONATO || watchProfession === ProfessionType.INOCCUPATO_ATRO) && (
            <div className="w-full">
              <label className="form-label" htmlFor={`familyComposition.${index}.endActivityYear`}>
                {t('FamilyCompositionPerson.endActivityYear.label')}
              </label>
              {/* onChange={(e) => setEmail(e.target.value)} */}
              <input
                {...register(`familyComposition.${index}.endActivityYear`, {
                  setValueAs: (v) => (v ? parseInt(v) : null),
                })}
                type="number"
                className="form-input"
                id={`familyComposition.${index}.endActivityYear`}
                placeholder={t('FamilyCompositionPerson.endActivityYear.placeholder')}
              />
            </div>
          )}
          <div className="w-full">
            <label className="form-label" htmlFor={`familyComposition.${index}.professionTypeRelationship`}>
              {t('FamilyCompositionPerson.professionTypeRelationship.label')}
            </label>
            {/* onChange={(e) => setEmail(e.target.value)} */}
            <select
              {...register(`familyComposition.${index}.professionTypeRelationship`)}
              className="form-input"
              id={`familyComposition.${index}.professionTypeRelationship`}
            >
              {Object.keys(ProfessionTypeRelationship).map((key, index) => (
                <option key={index} value={ProfessionTypeRelationship[key as keyof typeof ProfessionTypeRelationship]}>
                  {ProfessionTypeRelationship[key as keyof typeof ProfessionTypeRelationship]}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor={`familyComposition.${index}.professionContractType`}>
              {t('FamilyCompositionPerson.professionContractType.label')}
            </label>
            {/* onChange={(e) => setEmail(e.target.value)} */}
            <select
              {...register(`familyComposition.${index}.professionContractType`)}
              className="form-input"
              id={`familyComposition.${index}.professionContractType`}
            >
              {Object.keys(ProfessionContractType).map((key, index) => (
                <option key={index} value={ProfessionContractType[key as keyof typeof ProfessionContractType]}>
                  {ProfessionContractType[key as keyof typeof ProfessionContractType]}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full">
            <label className="form-label" htmlFor={`familyComposition.${index}.professionSector`}>
              {t('FamilyCompositionPerson.professionSector.label')}
            </label>
            {/* onChange={(e) => setEmail(e.target.value)} */}
            <select
              {...register(`familyComposition.${index}.professionSector`)}
              className="form-input"
              id={`familyComposition.${index}.professionSector`}
            >
              {Object.keys(ProfessionSector).map((key, index) => (
                <option key={index} value={ProfessionSector[key as keyof typeof ProfessionSector]}>
                  {ProfessionSector[key as keyof typeof ProfessionSector]}
                </option>
              ))}
            </select>
          </div>
          {watchProfessionTypeRelationship &&
            ![
              ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE,
              ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA,
            ].includes(watchProfessionTypeRelationship) && (
              <div className="w-full">
                <label className="form-label" htmlFor={`familyComposition.${index}.contractualLevel`}>
                  {t('FamilyCompositionPerson.contractualLevel.label')}
                </label>
                {/* onChange={(e) => setEmail(e.target.value)} */}
                <select
                  {...register(`familyComposition.${index}.contractualLevel`)}
                  className="form-input"
                  id={`familyComposition.${index}.contractualLevel`}
                >
                  {Object.keys(ContractualLevel).map((key, index) => (
                    <option key={index} value={ContractualLevel[key as keyof typeof ContractualLevel]}>
                      {ContractualLevel[key as keyof typeof ContractualLevel]}
                    </option>
                  ))}
                </select>
              </div>
            )}
          {watchProfessionTypeRelationship &&
            [
              ProfessionTypeRelationship.AUTONOMO_IMPRENDITORE,
              ProfessionTypeRelationship.AUTONOMO_PARTITA_IVA,
            ].includes(watchProfessionTypeRelationship) && (
              <>
                <div className="w-full">
                  <label className="form-label" htmlFor={`familyComposition.${index}.companyType`}>
                    {t('FamilyCompositionPerson.companyType.label')}
                  </label>
                  {/* onChange={(e) => setEmail(e.target.value)} */}
                  <select
                    {...register(`familyComposition.${index}.companyType`)}
                    className="form-input"
                    id={`familyComposition.${index}.companyType`}
                  >
                    {Object.keys(CompanyType).map((key, index) => (
                      <option key={index} value={CompanyType[key as keyof typeof CompanyType]}>
                        {CompanyType[key as keyof typeof CompanyType]}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-full">
                  <label className="form-label" htmlFor={`familyComposition.${index}.numMembers`}>
                    {t('FamilyCompositionPerson.numMembers.label')}
                  </label>
                  {/* onChange={(e) => setEmail(e.target.value)} */}
                  <input
                    {...register(`familyComposition.${index}.numMembers`, {
                      setValueAs: (v) => (v ? parseInt(v) : null),
                    })}
                    type="number"
                    className="form-input"
                    id={`familyComposition.${index}.numMembers`}
                    placeholder={t('FamilyCompositionPerson.numMembers.placeholder')}
                  />
                </div>

                <div className="w-full">
                  <label className="form-label" htmlFor={`familyComposition.${index}.numEmployees`}>
                    {t('FamilyCompositionPerson.numEmployees.label')}
                  </label>
                  {/* onChange={(e) => setEmail(e.target.value)} */}
                  <input
                    {...register(`familyComposition.${index}.numEmployees`, {
                      setValueAs: (v) => (v ? parseInt(v) : null),
                    })}
                    type="number"
                    className="form-input"
                    id={`familyComposition.${index}.numEmployees`}
                    placeholder={t('FamilyCompositionPerson.numEmployees.placeholder')}
                  />
                </div>
              </>
            )}

          <div className="w-full">
            <label className="form-label" htmlFor={`familyComposition.${index}.companyName`}>
              {t('FamilyCompositionPerson.companyName.label')}
            </label>
            {/* onChange={(e) => setEmail(e.target.value)} */}
            <input
              {...register(`familyComposition.${index}.companyName`)}
              type="text"
              className="form-input"
              id={`familyComposition.${index}.companyName`}
              placeholder={t('FamilyCompositionPerson.companyName.placeholder')}
            />
          </div>

          {/* <div className="w-full flex flex-row md:flex-col items-start gap-2 col-span-1 md:col-span-2 lg:col-span-3">
            <label
              className="block text-lightbluemazza text-sm font-bold mb-0"
              htmlFor={`familyComposition.${index}.extraordinaryExpenses`}
            >
              {t('FamilyCompositionPerson.extraordinaryExpenses.label')}
            </label>
            <input
              {...register(`familyComposition.${index}.extraordinaryExpenses`, {
                setValueAs: (v) => (v ? !!v : null),
              })}
              className="form-input w-4 h-4 mt-2.5 p-0 mb-4"
              id={`familyComposition.${index}.extraordinaryExpenses`}
              type="checkbox"
            />
          </div> */}
        </div>
      )}
    </div>
  );
};

export default FamilyCompositionPerson;
