import { forgotPasswordFn } from 'api/authApi';
import { Alert } from 'components/Shared/Alert/Alert';
import Modal from 'components/Shared/Modal/Modal';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { FetchStatus } from 'types';

interface IForgotPasswordDialog {
  isOpen: boolean;
  onClose: () => void;
}
const ForgotPasswordDialog: React.FC<IForgotPasswordDialog> = ({ isOpen, onClose }) => {
  const [status, setStatus] = useState<{
    status: FetchStatus;
    message?: string;
  }>({
    status: FetchStatus.UNDEFINED,
  });
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({ mode: 'all' });

  const navigate = useNavigate();
  const onSubmit = handleSubmit((data) => runForgotPasswordRequest(data.email));

  const modalTitle = t('ForgotPasswordDialog.title');

  const onLoginClick = () => {
    onClose();
    navigate('/auth/login');
  };

  const runForgotPasswordRequest = async (email: string) => {
    setStatus({ status: FetchStatus.LOADING });
    try {
      await forgotPasswordFn(email);
      setStatus({ status: FetchStatus.SUCCESS });
    } catch (e: any) {
      // console.error(e)
      let message = undefined;
      if (e.response.data && typeof e.response.data === 'string') message = e.response.data;
      if (message === 'Please verify your account') message = t('ForgotPasswordDialog.error.verify');
      setStatus({
        status: FetchStatus.ERROR,
        message: e.message,
      });
    }
  };

  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        {status.status === FetchStatus.SUCCESS ? (
          <div className="flex flex-col items-center gap-4">
            <Alert
              status="SUCCESS"
              title={t('ForgotPasswordDialog.success.title')}
              description={t('ForgotPasswordDialog.success.description')}
            />
            <button
              onClick={onLoginClick}
              className="transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
            >
              {t('ForgotPasswordDialog.login')}
            </button>
          </div>
        ) : (
          <form className="flex flex-col items-start gap-4 w-full" onSubmit={onSubmit}>
            <p className="text-sm text-gray-500">Inserisci la tua email per recuperare l'account</p>
            <div className="w-full">
              <label className="form-label" htmlFor="email">
                {t('ForgotPasswordDialog.email.label')}
              </label>
              {/* onChange={(e) => setEmail(e.target.value)} */}
              <input
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: t('ForgotPasswordDialog.email.error'),
                  },
                })}
                className="form-input"
                id="email"
                type="email"
                placeholder="Email"
              />
              {errors.email && <p className="input-error">{errors.email.message}</p>}
            </div>
            <button
              disabled={status.status === FetchStatus.LOADING || !isValid}
              className="self-center disabled:opacity-50 disabled:bg-blue-500 transition-colors ease-in-out duration-300  bg-blue-500 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              {status.status === FetchStatus.LOADING ? <ScaleLoader color="white" /> : t('ForgotPasswordDialog.reset')}
            </button>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default ForgotPasswordDialog;
