import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import it from './locales/it.json';

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['it', 'en'],
    resources: {
      en: {
        translation: en,
      },
      it: {
        translation: it,
      },
    },
  });
