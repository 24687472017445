import Modal from 'components/Shared/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaTrashAlt } from 'react-icons/fa';
import { ScaleLoader } from 'react-spinners';
import { FetchStatus } from 'types';
import { FileSchema, IApplication } from 'types/api/applications';

interface IUploadDetailsDialog {
  application: IApplication;
  selectedUpload: FileSchema;
  isOpen: boolean;
  onClose: () => void;
  uploadDetail: FileSchema;
  deleteUpload: () => void;
  downloadUpload: () => void;
  deleteUploadStatus: FetchStatus;
  downloadUploadStatus: FetchStatus;
}

const UploadDetailsDialog: React.FC<IUploadDetailsDialog> = ({
  application,
  selectedUpload,
  isOpen,
  onClose,
  uploadDetail,
  deleteUpload,
  downloadUpload,
  deleteUploadStatus,
  downloadUploadStatus,
}) => {
  const { t } = useTranslation();
  const modalTitle = t('UploadDetailsDialog.title');
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose} showCloseIcon={true}>
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <div>
          <label className="form-label" htmlFor="selectedResidence">
            {t('UploadDetailsDialog.name')}
          </label>
          <p className="font-bold text-md">{uploadDetail.originalName}</p>
        </div>
        <div>
          <label className="form-label" htmlFor="selectedResidence">
            {t('UploadDetailsDialog.type')}
          </label>
          <p className="font-bold text-md">{uploadDetail.mimetype}</p>
        </div>
        <div>
          <label className="form-label" htmlFor="selectedResidence">
            {t('UploadDetailsDialog.size')}
          </label>
          <p className="font-bold text-md">{`${(uploadDetail.size / 1024).toFixed(1)} KB`}</p>
        </div>

        <div className="w-full pt-4 flex flex-row items-center justify-end gap-4">
          <button
            disabled={downloadUploadStatus === FetchStatus.LOADING}
            onClick={() => {
              downloadUpload();
              onClose();
            }}
            className="bluemazza-button"
          >
            {downloadUploadStatus === FetchStatus.LOADING ? (
              <ScaleLoader color="white" />
            ) : (
              <span className="flex flex-row items-center gap-2">
                <FaDownload />
                <p>{t('UploadDetailsDialog.download')}</p>
              </span>
            )}
          </button>
          <button
            disabled={deleteUploadStatus === FetchStatus.LOADING}
            onClick={() => {
              deleteUpload();
              onClose();
            }}
            className="red-button"
          >
            {deleteUploadStatus === FetchStatus.LOADING ? (
              <ScaleLoader color="white" />
            ) : (
              <span className="flex flex-row items-center gap-2">
                <FaTrashAlt />
                <p>{t('UploadDetailsDialog.delete')}</p>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadDetailsDialog;
