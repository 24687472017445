import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaPaperclip } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { IAppeal, IResidence } from 'types/api/residences';

interface IApplicationComplete {
  residence: IResidence;
  appeal: IAppeal;
  openEditApplicationConfirm: () => void;
}
const ApplicationComplete: React.FC<IApplicationComplete> = ({ residence, appeal, openEditApplicationConfirm }) => {
  const navigate = useNavigate();
  const appealExpired = dayjs(appeal.expiryDate) < dayjs();
  const navigateToUploads = () => navigate('/application/uploads');
  const { t } = useTranslation();

  return (
    <div className="animate-fade-in-up flex flex-col items-center gap-4  mt-16 shadow border bg-white rounded p-4">
      <h1 className="text-lightbluemazza font-bold text-3xl text-center">{t('ApplicationComplete.title')}</h1>
      <h2 className="text-black font-bold text-xl text-center">{t('ApplicationComplete.description1')}</h2>
      <h3 className="text-bluemazza font-bold text-lg text-center">
        {residence.name} - {dayjs(appeal.date).format('DD/MM/YYYY')}
      </h3>
      <h2 className="text-green-500 text-xl font-bold">{t('ApplicationComplete.description2')}</h2>
      <h3 className="text-bluemazza font-medium text-md underline text-center">
        {t('ApplicationComplete.description3')}
      </h3>
      {appealExpired ? (
        <h3 className="text-red-500 font-medium text-md underline text-center">
          {t('ApplicationComplete.description4')}
        </h3>
      ) : (
        <h3 className="text-black font-medium text-sm text-center">
          {t('ApplicationComplete.description5', { date: dayjs(appeal.expiryDate).fromNow() })}
        </h3>
      )}
    </div>
  );
};
export default ApplicationComplete;
