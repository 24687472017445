import { useMemo } from "react";
import { useAuthentication } from "context/authContext";

import { useApplication } from "context/applicationContext";
import { useResidences } from "context/residencesContext";
import { Header } from "components/header";

import Confetti from "react-confetti";

import { IAppeal, IResidence } from "types/api/residences";
import { IApplication, IApplicationStatus } from "types/api/applications";
import ApplicationRejected from "components/Application/Conclusion/ApplicationRejected/ApplicationRejected";
import ApplicationPresent from "components/Application/Conclusion/ApplicationPresent/ApplicationPresent";
import ApplicationWithdrawn from "components/Application/Conclusion/ApplicationWithdrawn/ApplicationWithdrawn";
import ApplicationWinner from "components/Application/Conclusion/ApplicationWinner/ApplicationWinner";
import ApplicationLoser from "components/Application/Conclusion/ApplicationLoser/ApplicationLoser";
import ApplicationComplete from "components/Application/Conclusion/ApplicationComplete/ApplicationComplete";

function Conclusion() {
  const { setUserLogout, userSession } = useAuthentication();
  const { application, userProfile, openEditApplicationConfirm } =
    useApplication();
  const { residences, appeals } = useResidences();

  const applicationAppeal = useMemo(
    () => appeals.find((appeal) => appeal._id === application?.appeal_id),
    [application, appeals]
  );
  const applicationResidence = useMemo(
    () =>
      residences.find(
        (residence) => residence._id === applicationAppeal?.residence_id
      ),
    [applicationAppeal, residences]
  );

  return (
    <>
      {application &&
        [IApplicationStatus.WINNER].includes(application.status) && (
          <Confetti />
        )}
      <Header
        userProfile={userProfile}
        userSession={userSession}
        setUserLogout={() => setUserLogout(false)}
      />
      {applicationResidence && applicationAppeal && application && (
        <ConclusionBody
          openEditApplicationConfirm={openEditApplicationConfirm}
          application={application}
          selectedAppeal={applicationAppeal}
          selectedResidence={applicationResidence}
        />
      )}
    </>
  );
}

export default Conclusion;

interface IConclusionBody {
  selectedResidence: IResidence;
  selectedAppeal: IAppeal;
  application: IApplication;
  openEditApplicationConfirm: () => void;
}
const ConclusionBody: React.FC<IConclusionBody> = ({
  selectedResidence,
  selectedAppeal,
  application,
  openEditApplicationConfirm,
}) => {
  const commonProps = { residence: selectedResidence, appeal: selectedAppeal };

  switch (application.status) {
    case IApplicationStatus.APPLICATION_COMPLETE:
      return (
        <ApplicationComplete
          {...commonProps}
          openEditApplicationConfirm={openEditApplicationConfirm}
        />
      );

    case IApplicationStatus.APPLICATION_ACCEPTED:
      return (
        <ApplicationComplete
          {...commonProps}
          openEditApplicationConfirm={openEditApplicationConfirm}
        />
      );
    case IApplicationStatus.APPLICATION_REJECTED:
      return <ApplicationRejected {...commonProps} />;

    case IApplicationStatus.PRESENT:
      return <ApplicationPresent {...commonProps} />;

    case IApplicationStatus.WITHDRAWN:
      return <ApplicationWithdrawn {...commonProps} />;

    case IApplicationStatus.WINNER:
      return <ApplicationWinner {...commonProps} />;

    case IApplicationStatus.LOSER:
      return <ApplicationLoser {...commonProps} />;
    default:
      return <></>;
  }
};
