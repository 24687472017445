import Modal from 'components/Shared/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';

interface IDeleteConfirmationDialog {
  isOpen: boolean;
  onClose: () => void;
  removeMember: () => void;
}

const DeleteConfirmationDialog: React.FC<IDeleteConfirmationDialog> = ({ isOpen, onClose, removeMember }) => {
  const { t } = useTranslation();
  const modalTitle = t('DeleteConfirmationDialog.title');

  const onDeleteClick = () => {
    removeMember();
    onClose();
  };
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose} type="danger">
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <p className="text-lg text-bold">{t('DeleteConfirmationDialog.title')}</p>
        <div className="w-full flex flex-row items-center justify-between">
          <button onClick={onClose} className="bluemazza-button">
            {t('DeleteConfirmationDialog.cancel')}
          </button>
          <button onClick={onDeleteClick} className="red-button">
            <span className="flex flex-row items-center gap-2">
              <FaTrashAlt />
              {t('DeleteConfirmationDialog.delete')}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationDialog;
