import { Alert } from 'components/Shared/Alert/Alert';
import { useAuthentication } from 'context/authContext';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { FetchStatus } from 'types';

interface ILogin {
  formRef: React.MutableRefObject<any>;
  openForgotPasswordDialog: () => void;
}

const Login: React.FC<ILogin> = ({ formRef, openForgotPasswordDialog }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ email: string; password: string }>({ mode: 'all' });
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { runLoginRequest, loginRequestStatus } = useAuthentication();

  const onSubmit = handleSubmit((data) => runLoginRequest(data.email, data.password));

  const loginRequestError = loginRequestStatus.message ? loginRequestStatus.message : t('Login.error');

  const goToRegistration = () => navigate('/auth/registration');
  return (
    <>
      <div ref={formRef} className="flex flex-row justify-between align-middle mb-4">
        <h1 className="text-2xl text-white font-normal">{t('Login.a1')}</h1>
        <button onClick={goToRegistration} className="bluemazza-button" type="button">
          {t('Login.registration')}
        </button>
      </div>
      <form onSubmit={onSubmit}>
        {loginRequestStatus.status === FetchStatus.ERROR && (
          <div className="mb-4">
            <Alert status="WARNING" title={t('Login.a2')} description={loginRequestError} />
          </div>
        )}

        <div className="mb-4">
          <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
            {t('Login.email.label')}
          </label>
          <input
            {...register('email', {
              required: true,
              pattern: { value: /^\S+@\S+$/i, message: 'Email.email.error' },
            })}
            className="auth-input"
            id="email"
            type="email"
            placeholder="Email"
          />
          <p className="input-error clear h-px">{errors.email?.message}</p>
        </div>
        <div className="mb-4">
          <label className=" relative block text-white text-sm font-bold mb-2" htmlFor="password">
            {t('Login.password.label')}
          </label>

          <div className="relative block mb-3 ">
            <input
              {...register('password', {
                required: true,
                minLength: {
                  value: 6,
                  message: 'Login.password.error',
                },
              })}
              className="auth-input"
              id="password"
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="text-black w-4 h-4 absolute top-1/2 transform -translate-y-1/2 right-2"
            >
              {showPassword ? <FaEyeSlash className="pointer-events-none" /> : <FaEye />}
            </button>
          </div>
          <p className="input-error clear h-px">{errors.password?.message}</p>
        </div>
        <div className="flex items-center justify-between w-full">
          <a
            className="cursor-pointer transition ease-in-out duration-300 inline-block align-baseline font-medium text-sm text-white underline underline-offset-4 hover:-translate-y-1"
            onClick={openForgotPasswordDialog}
          >
            {t('Login.forgotPassword')}
          </a>
          <button
            disabled={loginRequestStatus.status === FetchStatus.LOADING || !isValid}
            className="bluemazza-button"
            type="submit"
          >
            {loginRequestStatus.status === FetchStatus.LOADING ? <ScaleLoader color="white" /> : t('Login.login')}
          </button>
        </div>
      </form>
    </>
  );
};

export default Login;
