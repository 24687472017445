import { toast } from "react-toastify"

const sendNotification = (
  title: string,
  description: string,
  notificationStatus: "success" | "error" | "warning" | "info",
  isClosable?: boolean,
  duration?: number
) => {
  const toastType = () => {
    switch (notificationStatus) {
      case "error":
        return toast.error
      case "success":
        return toast.success
      case "warning":
        return toast.warning
      default:
        return toast
    }
  }

  return toastType()(title, {
    position: "bottom-left",
    autoClose: duration || 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

export default sendNotification
