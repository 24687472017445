import { useRef, useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ForgotPasswordDialog from 'components/Auth/ForgotPasswordDialog/ForgotPasswordDialog';
import VerifyAccountDialog from 'components/Auth/VerifyAccountDialog/VerifyAccountDialog';
import ResetPasswordDialog from 'components/Auth/ResetPasswordDialog/ResetPasswordDialog';
import Registration from 'components/Auth/Registration/Registration';
import Login from 'components/Auth/Login/Login';
import DescriptionBox from 'components/Auth/DescriptionSidebar/DescriptionBox';

function AuthComponent() {
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const loginRef = useRef<HTMLFormElement>(null);

  const { userId, verificationCode, resetPasswordCode } = useParams();
  const { pathname } = useLocation();

  const [isVerifyAccountModalOpen, setIsVerifyAccountModalOpen] = useState<boolean>(false);
  const [isForgotAccountModalOpen, setIsForgotAccountModalOpen] = useState<boolean>(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (pathname === '/auth/login') setIsLogin(true);
    else if (pathname === '/auth/registration') setIsLogin(false);
    else if (pathname === '/auth/verify') setIsVerifyAccountModalOpen(true);
  }, [pathname]);

  useEffect(() => {
    if (userId && verificationCode) {
      setIsVerifyAccountModalOpen(true);
    }
  }, [userId, verificationCode]);

  useEffect(() => {
    if (userId && resetPasswordCode) {
      setIsResetPasswordModalOpen(true);
    }
  }, [userId, resetPasswordCode]);

  return (
    <div className="w-full">
      <ForgotPasswordDialog isOpen={isForgotAccountModalOpen} onClose={() => setIsForgotAccountModalOpen(false)} />
      {userId && verificationCode && (
        <VerifyAccountDialog
          isOpen={isVerifyAccountModalOpen}
          onClose={() => setIsVerifyAccountModalOpen(false)}
          userId={userId}
          verificationCode={verificationCode}
        />
      )}
      {userId && resetPasswordCode && (
        <ResetPasswordDialog
          userId={userId}
          resetPasswordCode={resetPasswordCode}
          isOpen={isResetPasswordModalOpen}
          onClose={() => setIsResetPasswordModalOpen(false)}
        />
      )}
      {isLogin ? (
        <Login openForgotPasswordDialog={() => setIsForgotAccountModalOpen(true)} formRef={loginRef} />
      ) : (
        <Registration />
      )}
    </div>
  );
}

const AuthPageContainer = () => {
  return (
    <div className="w-full flex sm:flex-col md:flex-row justify-center items-center flex-wrap gap-4 py-4 px-2 md:px-0 ">
      <DescriptionBox />
      <div className="animate-fade-in-right w-full md:w-5/12 flex-1 shadow  rounded bg-bluemazza px-4 py-8">
        <AuthComponent />
      </div>
    </div>
  );
};

export default AuthPageContainer;
