import { useApplication } from 'context/applicationContext';
import { useResidences } from 'context/residencesContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaExclamationCircle } from 'react-icons/fa';

const FeedbackCompletation = () => {
  const { t } = useTranslation();
  const { application, userProfile } = useApplication();
  const { residences, appeals } = useResidences();
  const requiredDocuments = useMemo(() => {
    if (!userProfile) return [];

    const isFirstEnrollment = userProfile.curriculum?.university.firstUniEnrollment ? true : false;
    const isAlreadyEnrolled = userProfile.curriculum?.university.alreadyEnrolled ? true : false;
    const hasBachelor = userProfile.curriculum?.university.alreadyEnrolled?.hasBachelor ? true : false;

    const applicationAppeal = appeals.find((appeal) => appeal._id === application?.appeal_id);
    if (!applicationAppeal) return [];

    const residence = residences.find((residence) => residence._id === applicationAppeal?.residence_id);
    if (!residence) return [];

    return residence.admRequiredDocuments.filter((reqUpload) => {
      // se l'utente dichiara di non inviare isee viene rimosso
      if (reqUpload.key === 'isee' && userProfile.registry?.notSubmitIsee) {
        return false;
      } else if (
        (reqUpload.required.alreadyEnrolled && isAlreadyEnrolled) ||
        (reqUpload.required.firstEnrollment && isFirstEnrollment) ||
        (reqUpload.required.hasBachelor && hasBachelor)
      ) {
        return true;
      }
      return false;
    });
  }, [application, appeals, residences, userProfile]);

  if (
    requiredDocuments.length &&
    (!application?.uploads?.length ||
      (application?.uploads?.length && application?.uploads?.length < requiredDocuments.length))
  ) {
    return (
      <div className="bg-red-700 text-white px-8 py-2 flex items-center justify-between space-x-4">
        <FaExclamationCircle size={24} />
        <p className="text-sm">{t('FeedbackCompletation.description')}</p>
      </div>
    );
  }

  return null;
};

export default FeedbackCompletation;
