import { useEffect, useState, useRef, useMemo } from 'react';
import { FetchStatus, StepComponentProps } from 'types';
import FormStepManager from 'components/Application/Form/FormStepManager/FormStepManager';
import dayjs from 'utils/dayjs';
import { useApplication } from 'context/applicationContext';
import { useResidences } from 'context/residencesContext';
import sendNotification from 'utils/notifications';
import { ScaleLoader } from 'react-spinners';
import { FaPaperPlane } from 'react-icons/fa';

import { IAppeal, IResidence } from 'types/api/residences';
import { updateApplicationFn } from 'api/applicationsApi';
import { useNavigate } from 'react-router-dom';
import FeedbackCompletation from 'components/Application/Form/FeedbackCompletation';

function DescriptionBar({
  selectedResidenceId,
  residences,
}: {
  selectedResidenceId: string | undefined;
  residences: IResidence[];
}) {
  const seletedResidence = residences.find((residence) => residence._id === selectedResidenceId);

  return (
    <div className="animate-fade-in-down md:animate-fade-in-left mt-4 md:mt-0 flex w-full md:w-4/12 flex-col items-center md:items-start gap-4">
      <h3 className="flex flex-row items-center text-black font-bold text-xl">
        <FaPaperPlane />
        <span className="pl-2">Invio Domanda</span>
      </h3>
      <p className="text-black font-medium text-md">
        Con l’invio della domanda lo studente si candida a partecipare al concorso di ammissione al Collegio Mazza. Per
        procedere è necessario indicare per quale appello del concorso si intende scegliere, accettare la
        caratterizzazione del Collegio di merito e prendere visione dell’informativa privacy.
      </p>
      <p className="text-black font-medium text-md">
        La conferma della data scelta per il Concorso verrà comunicata dal Collegio quanto prima.
      </p>
      <p className="text-black font-medium text-md">
        Dopo l’invio della candidatura, lo studente dovrà caricare entro la data di scadenza della domanda prevista dal
        Bando di concorso la documentazione personale richiesta.
      </p>
    </div>
  );
}
export default function SendApplication({
  steps,
  currentStep,
  nextStep,
  prevStep,
  profile,
  profileFetchStatus,
  setProfile,
}: StepComponentProps) {
  const navigate = useNavigate();
  const { application, setApplication } = useApplication();
  const { appeals, residences } = useResidences();

  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [characterizationChecked, setCharacterizationChecked] = useState<boolean>(false);
  const [selectedResidence, setSelectedResidence] = useState<string | undefined>(undefined);
  const [selectedAppeal, setSelectedAppeal] = useState<string | undefined>(undefined);
  const [selectedPromoCode, setSelectedPromoCode] = useState<string | undefined>(undefined);
  const [residenceAvailableAppeals, setResidenceAvailableAppeals] = useState<IAppeal[]>([]);
  const [residenceNotStartingYetAppeals, setResidenceNotStartingYetAppeals] = useState<IAppeal[]>([]);
  const [updateAppealFetchStatus, setUpdateAppealFetchStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const residenceAppeals = useMemo(
    () => appeals.filter((appeal) => appeal.residence_id === selectedResidence),
    [appeals, selectedResidence]
  );

  const fieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fieldRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (application) {
      const relatedAppeal = appeals.find((appeal) => appeal._id === application.appeal_id);
      if (application.promoCode) setSelectedPromoCode(application.promoCode);
      if (relatedAppeal) {
        // setSelectedAppeal(relatedAppeal._id)
        const relatedResidence = residences.find((residence) => residence._id === relatedAppeal.residence_id);
        if (relatedResidence) {
          setSelectedResidence(relatedResidence._id);
        }
      }
    }
  }, [application, appeals, residences]);

  useEffect(() => {
    if (residenceAppeals.length > 0) {
      const availableAppeals = residenceAppeals.filter(
        (appeal) => dayjs(appeal.acceptCandidacyFromDate) < dayjs() && dayjs(appeal.expiryDate) > dayjs()
      );
      setResidenceNotStartingYetAppeals(residenceAppeals.filter((appeal) => dayjs(appeal.expiryDate) > dayjs()));
      setResidenceAvailableAppeals(availableAppeals);
      if (availableAppeals.length)
        if (application) {
          const residenceIncludeApplicationAppeal = availableAppeals.find(
            (appeal) => appeal._id === application.appeal_id
          );
          if (residenceIncludeApplicationAppeal) setSelectedAppeal(residenceIncludeApplicationAppeal._id);
          else setSelectedAppeal(availableAppeals[0]._id);
        } else setSelectedAppeal(availableAppeals[0]._id);
      else setSelectedAppeal(undefined);
    } else {
      setResidenceAvailableAppeals([]);
      setSelectedAppeal(undefined);
      setResidenceNotStartingYetAppeals([]);
    }
  }, [residenceAppeals, application]);

  const updateAppealAndReview = async () => {
    if (selectedAppeal && application) {
      if (selectedAppeal === application?.appeal_id && selectedPromoCode === application.promoCode)
        //If the user did not change the appeal
        navigate('/application/send/confirm');
      else {
        setUpdateAppealFetchStatus(FetchStatus.LOADING);
        try {
          const updatedApplication = await updateApplicationFn({
            appeal_id: selectedAppeal,
            ...(selectedPromoCode && { promoCode: selectedPromoCode }),
          });
          setApplication(updatedApplication);
          setUpdateAppealFetchStatus(FetchStatus.SUCCESS);
          sendNotification(
            'Aggiornamento candidatura eseguito',
            'Dati aggiornati correttamente',
            'success',
            true,
            5000
          );
          navigate('/application/send/confirm');
        } catch (e: any) {
          // console.error(e)
          sendNotification(
            'Aggiornamento candidatura fallito',
            'Aggiornamento candidatura eseguito',
            'error',
            true,
            5000
          );
          setUpdateAppealFetchStatus(FetchStatus.ERROR);
        }
      }
    }
  };

  const isFormValid = characterizationChecked && privacyChecked && selectedResidence && selectedAppeal;
  return (
    <div ref={fieldRef} className="w-full flex flex-col">
      <FormStepManager
        profile={profile}
        steps={steps}
        prevStep={prevStep}
        currentStep={currentStep}
        nextStep={undefined}
      />
      <div className="w-full flex flex-col md:flex-row justify-between flex-wrap">
        <DescriptionBar selectedResidenceId={selectedResidence} residences={residences} />
        <div className="animate-fade-in-up md:animate-fade-in-right w-full md:w-8/12 flex flex-col items-center md:items-start md:pl-8 gap-4 mt-8 md:mt-0">
          <h3 className="text-center md:text-left form-title">Con l'invio della domanda il candidato dichiara</h3>
          <div className="flex flex-row items-center justify-between gap-4">
            <input
              className="form-input w-auto"
              type="checkbox"
              defaultChecked={characterizationChecked}
              onChange={(e) => setCharacterizationChecked(e.target.checked)}
            />
            <p> Di accettare la caratterizzazione del Collegio di Merito come espressa dal Bando di concorso</p>
          </div>
          <div className="flex flex-row items-center justify-between gap-4">
            <input
              className="form-input w-auto"
              type="checkbox"
              defaultChecked={privacyChecked}
              onChange={(e) => setPrivacyChecked(e.target.checked)}
            />
            <p>
              Di aver preso visione dell’informativa privacy (
              <a
                href={process.env.PUBLIC_URL + '/pdf/privacy-policy.pdf'}
                target="_blank"
                className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                rel="noreferrer"
              >
                leggi
              </a>
              ), dell'informativa candidati (
              <a
                href={process.env.PUBLIC_URL + '/pdf/informativa-candicati.pdf'}
                target="_blank"
                className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                rel="noreferrer"
              >
                leggi
              </a>
              ) e di acconsentire al trattamento dei dati personali
            </p>
          </div>
          <div className="flex flex-col md:flex-row mb-4 gap-4 w-full">
            <div className="w-full md:w-6/12 flex flex-col items-start">
              <div>
                <label className="form-label" htmlFor="selectedResidence">
                  Di candidarsi per il concorso del bando
                </label>
                <select
                  className="form-input"
                  id="selectedResidence"
                  value={selectedResidence}
                  onChange={(e) => setSelectedResidence(e.target.value as string)}
                >
                  {residences.map((residence, idx) => (
                    <option key={idx} value={residence._id}>
                      {residence.name}
                    </option>
                  ))}
                </select>
              </div>
              {residences.find((residence) => selectedResidence === residence._id)?.promoCodeEnabled &&
                selectedAppeal && (
                  <div className="mt-4">
                    <label className="form-label" htmlFor="selectedResidence">
                      Codice promozionale (opzionale)
                    </label>
                    <input
                      type="text"
                      className="form-input"
                      value={selectedPromoCode}
                      onChange={(e) => setSelectedPromoCode(e.target.value)}
                      name="promoCode"
                      id="promoCode"
                    />
                  </div>
                )}
            </div>
            <div className="w-full md:w-6/12">
              <label className="form-label" htmlFor="selectedAppealDate">
                Previsto per la data
              </label>
              {residenceAvailableAppeals.length ? (
                <select
                  className="form-input"
                  id="selectedAppealDate"
                  value={selectedAppeal}
                  onChange={(e) => setSelectedAppeal(e.target.value as string)}
                >
                  {residenceAvailableAppeals.map((appeal, idx) => {
                    const isDisabled = dayjs(appeal.acceptCandidacyFromDate) > dayjs();
                    return (
                      <option key={idx} value={appeal._id} disabled={isDisabled}>
                        {dayjs(appeal.date).format('DD/MM/YYYY')} {isDisabled && '(Candidature non ancora aperte)'}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <div className="flex flex-col items-start">
                  <p className="text-lg font-bold underline">Nessun appello disponibile</p>
                  {residenceNotStartingYetAppeals.length > 0 && (
                    <div className="text-xs text-orange-500 font-medium">
                      <p className="text-sm">
                        {residenceNotStartingYetAppeals.length}{' '}
                        {residenceNotStartingYetAppeals.length === 1 ? 'appello' : 'appelli'} in partenza
                      </p>
                      <p className="underline underline-offset-4">
                        Il primo disponibile ({dayjs(residenceNotStartingYetAppeals[0].date).format('DD/MM/YYYY')})
                        aprirà tra {dayjs(residenceNotStartingYetAppeals[0].acceptCandidacyFromDate).fromNow()}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <button
            className="bluemazza-submit-button md:self-center flex flex-col items-center h-24 md:h-auto"
            disabled={!isFormValid || updateAppealFetchStatus === FetchStatus.LOADING}
            onClick={updateAppealAndReview}
          >
            {updateAppealFetchStatus === FetchStatus.LOADING ? (
              <ScaleLoader color="white" />
            ) : (
              <>
                <span className="font-bold">Salva e prosegui</span>
                <p className="underline text-sm">Non preoccuparti, potrai modificare la domanda anche dopo l'invio</p>
              </>
            )}{' '}
          </button>
          <div className="mx-auto">
            <FeedbackCompletation />
          </div>
        </div>
      </div>
    </div>
  );
}
