import axios from 'axios';
import {
  ILoginRequest,
  ILoginResponse,
  IRefreshTokenResponse,
  IRegisterUserRequest,
  IResetPasswordRequest,
  IVerifyUserRequest,
} from 'types/api/auth';

export async function registerFn(registerBody: IRegisterUserRequest) {
  const response = await axios.post('/api/auth', registerBody);
  return response.data;
}

export async function loginFn(loginBody: ILoginRequest): Promise<ILoginResponse> {
  const response = await axios.post('/api/auth/login', loginBody);
  return response.data;
}

export async function verifyUserFn(verifyBody: IVerifyUserRequest) {
  const response = await axios.post('/api/auth/verify', verifyBody);
  return response.data;
}

export async function forgotPasswordFn(email: string) {
  const response = await axios.post('/api/auth/forgotPassword', { email });
  return response.data;
}

export async function resetPasswordFn(resetPasswordBody: IResetPasswordRequest) {
  const response = await axios.post('/api/auth/resetPassword', resetPasswordBody);
  return response.data;
}

export async function refreshTokenFn(refreshToken: string): Promise<IRefreshTokenResponse> {
  const response = await axios.post('/api/auth/refreshToken', null, {
    headers: {
      'x-refresh': refreshToken,
    },
  });
  return response.data;
}
