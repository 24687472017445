import { useTranslation } from 'react-i18next';

const DescriptionBox = () => {
  const { t } = useTranslation();
  return (
    <div className="flex-1 animate-fade-in-left sm:w-full md:w-5/12 bg-slate-200 border shadow rounded  px-4 py-8 flex flex-col justify-between flex-wrap ">
      <div className="sm:w-8/12 md:w-6/12 mb-8 ">
        <img className=" height-auto" src="/images/logo.png" alt="logo" />
      </div>
      <h1 className="color: text-lightbluemazza text-4xl font-medium mb-4">{t('DescriptionBox.title')}</h1>
      <p className="text-lg font-light mb-2 ">{t('DescriptionBox.description1')}</p>
      <p className="text-lg font-light mb-2 ">{t('DescriptionBox.description2')}</p>
      <p className="text-lg font-light mb-2 ">{t('DescriptionBox.description3')}</p>
    </div>
  );
};

export default DescriptionBox;
