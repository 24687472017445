import { useState, Fragment, useEffect } from 'react';
import { StepModel } from 'types';
import { FaArrowRight, FaArrowLeft, FaWindowClose } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Transition, Dialog } from '@headlessui/react';
import { updateUserProfileType } from 'types/zod';
import { useTranslation } from 'react-i18next';

type FormProgressManagerProps = {
  profile: updateUserProfileType | undefined;
  steps: StepModel[];
  currentStep: StepModel;
  prevStep: StepModel | undefined;
  nextStep: (() => void) | undefined;
};
export default function FormStepManager({ steps, prevStep, currentStep, nextStep, profile }: FormProgressManagerProps) {
  return (
    <>
      <MobileProgressBar
        profile={profile}
        steps={steps}
        currentStep={currentStep}
        nextStep={nextStep}
        prevStep={prevStep}
      />
      <DesktopProgressBar
        profile={profile}
        steps={steps}
        currentStep={currentStep}
        nextStep={nextStep}
        prevStep={prevStep}
      />
    </>
  );
}

function MobileStepManager({
  isOpen,
  onClose,
  steps,
  currentStep,
  nextStep,
  prevStep,
  profile,
}: { isOpen: boolean; onClose: () => void } & FormProgressManagerProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentStepIndex = steps.findIndex((step) => step.path === currentStep.path);

  const [completionPercentage, setCompletionPercentage] = useState<number>(0);

  useEffect(() => {
    let perc = 0;
    if (profile) {
      if (profile.registry) perc += 25;
      if (profile.curriculum) perc += 25;
      if (profile.familyComposition?.length) perc += 25;
      if (profile.questions?.length) perc += 25;
    }
    setCompletionPercentage(perc);
  }, [profile]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
                <div className="w-full flex items-center justify-between">
                  <Dialog.Title as="h3" className="text-xl font-bold text-black">
                    {t('FormStepManager.title')}
                  </Dialog.Title>
                  <p onClick={onClose} className="cursor-pointer text-2xl text-bluemazza font-bold">
                    <FaWindowClose />
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center gap-2 w-full py-4 ">
                  {steps.map((step, idx) => {
                    return (
                      <div
                        key={idx}
                        onClick={() => {
                          if (idx < currentStepIndex) {
                            navigate(`/application/form/${step.path}`);
                            onClose();
                          }
                        }}
                        className={`w-full ${
                          idx === currentStepIndex
                            ? 'bg-bluemazza text-white'
                            : idx < currentStepIndex
                            ? 'cursor-pointer bg-lightbluemazza text-white'
                            : 'bg-slate-200'
                        } shadow flex flex-row justify-between items-center  p-2 rounded`}
                      >
                        <step.icon />
                        <p className="ml-2">{step.name}</p>
                      </div>
                    );
                  })}
                </div>

                <div className="w-full flex flex-col justify-between items-start">
                  <p
                    className={` ${
                      completionPercentage < 100 ? 'text-orange-500' : 'text-green-500'
                    } text-lg font-bold`}
                  >
                    {completionPercentage === 100
                      ? t('FormStepManager.description1')
                      : t('FormStepManager.description2')}
                  </p>
                  <p
                    className={` ${
                      completionPercentage < 100 ? 'text-orange-500' : 'text-green-500'
                    } text-md font-medium`}
                  >
                    {t('FormStepManager.description3', { completionPercentage })}
                  </p>
                  {completionPercentage === 100 && (
                    <p
                      onClick={() => navigate('/application/form/sendApplication')}
                      className={` text-green-500 text-sm font-medium underline underline-offset-4 cursor-pointer transition ease duration-300 hover:text-green-600 `}
                    >
                      {t('FormStepManager.description4')}
                    </p>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
function MobileProgressBar({ profile, steps, currentStep, nextStep, prevStep }: FormProgressManagerProps) {
  const currentStepIndex = steps.findIndex((step) => step.path === currentStep.path);
  const [isStepManagerOpen, setIsStepManagerOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <div className="flex md:hidden sticky top-0 flex-row justify-between w-full py-4">
      <MobileStepManager
        profile={profile}
        isOpen={isStepManagerOpen}
        onClose={() => setIsStepManagerOpen(false)}
        steps={steps}
        currentStep={currentStep}
        nextStep={nextStep}
        prevStep={prevStep}
      />
      <button
        onClick={() => prevStep && navigate(`/application/form/${prevStep.path}`)}
        disabled={!prevStep}
        className="bg-bluemazza text-white disabled:bg-slate-200 disabled:text-black disabled:cursor-auto hover:bg-lightbluemazza shadow p-4 rounded h-full text-lg cursor-pointer font-extrabold"
        type="button"
      >
        <FaArrowLeft />
      </button>
      <div className="shadow flex flex-row justify-between items-center bg-slate-200 p-2 rounded">
        <div className="flex flex-col items-center">
          <div
            onClick={() => setIsStepManagerOpen(true)}
            className={`flex flex-row items-center  'underline underline-offset-4 text-lightbluemazza font-extrabold' text-xl cursor-pointer`}
          >
            <div className="">
              <currentStep.icon />
            </div>
            <p onClick={() => navigate(`/application/form/${currentStep.path}`)} className="ml-2">
              {currentStep.name}
            </p>
          </div>
          <div className="flex flex-row justify-between items-center gap-1">
            {steps.map((step, idx) => {
              return (
                <div
                  key={idx}
                  className={`transition ease duration-300 ${currentStepIndex === idx ? 'w-2 h-2' : 'w-1 h-1'} ${
                    currentStepIndex && idx < currentStepIndex ? 'bg-lightbluemazza' : 'bg-slate-400'
                  } rounded-full`}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
      <button
        onClick={() => nextStep && nextStep()}
        disabled={!nextStep}
        className="bg-bluemazza text-white disabled:bg-slate-200 disabled:text-black disabled:cursor-auto hover:bg-lightbluemazza shadow p-4 rounded h-full text-lg  cursor-pointer font-extrabold"
        type="button"
      >
        <FaArrowRight />
      </button>
    </div>
  );
}

function DesktopProgressBar({ steps, currentStep, nextStep, prevStep }: FormProgressManagerProps) {
  const currentStepIndex = steps.findIndex((step) => step.path === currentStep.path);
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="hidden md:flex sticky top-4 flex-row justify-between w-full my-8">
      <div className="w-full bg-slate-200  shadow border-2  flex flex-row justify-between items-center  p-2 rounded">
        {steps.map((step, idx) => {
          const isActive = step.path === currentStep.path;
          return (
            <div
              key={idx}
              className={`${idx < currentStepIndex && 'cursor-pointer'} transition ease duration-300 ${
                idx < currentStepIndex && 'hover:text-lightbluemazza'
              } flex flex-row items-center ${
                isActive
                  ? 'underline underline-offset-4 text-bluemazza font-extrabold'
                  : idx < currentStepIndex
                  ? 'text-lightbluemazza'
                  : 'text-black font-medium'
              }  text-sm xl:text-lg `}
            >
              <step.icon />
              <p onClick={() => idx <= currentStepIndex && navigate(`/application/form/${step.path}`)} className="ml-2">
                {t(`DesktopProgressBar.${step.name}`)}
              </p>
            </div>
          );
        })}
        <div className="flex flex-row justify-between gap-4 items-center">
          <button
            onClick={() => prevStep && navigate(`/application/form/${prevStep.path}`)}
            disabled={!prevStep}
            className="bg-bluemazza text-white disabled:bg-slate-200 disabled:text-black disabled:cursor-auto hover:bg-lightbluemazza shadow p-4 rounded h-full text-lg xl:text-xl cursor-pointer font-extrabold transition ease duration-300"
            type="button"
          >
            <FaArrowLeft />
          </button>

          <button
            onClick={() => nextStep && nextStep()}
            disabled={!nextStep}
            className="bg-bluemazza text-white disabled:bg-slate-200 disabled:text-black disabled:cursor-auto hover:bg-lightbluemazza  shadow p-4 rounded h-full text-lg xl:text-xl cursor-pointer font-extrabold transition ease duration-300"
            type="button"
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
}
