import { useState, Fragment, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { FetchStatus } from '../../types';

import { useAuthentication } from 'context/authContext';
import sendNotification from 'utils/notifications';

import { IAdmRequiredDocument, IAppeal, IResidence, ResidenceMode } from 'types/api/residences';

import { IApplication, IApplicationStatus } from 'types/api/applications';
import { FileSchema } from 'types/api/applications';
import { FaEdit, FaEye } from 'react-icons/fa';

import { useApplication } from 'context/applicationContext';
import { useResidences } from 'context/residencesContext';
import dayjs from 'utils/dayjs';
import { Header } from 'components/header';

import Dropzone from 'react-dropzone';
import {
  deleteApplicationUploadFn,
  downloadAdmissionReqPdfFn,
  downloadApplicationUploadFn,
  updateApplicationFn,
  uploadApplicationUploadFn,
} from 'api/applicationsApi';
import UploadDetailsDialog from 'components/Application/Uploads/UploadsDetailDialog/UploadsDetailDialog';
import DescriptionSidebar from 'components/Application/Uploads/DescriptionSidebar/DescriptionSidebar';
import FeedbackCompletation from 'components/Application/Form/FeedbackCompletation';
import { useTranslation } from 'react-i18next';

function ApplicationUploads() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setUserLogout, userSession } = useAuthentication();
  const { application, setApplication, userProfile, openEditApplicationConfirm } = useApplication();
  const { residences, appeals } = useResidences();

  const [parsedRequiredUploads, setParsedRequiredUploads] = useState<IAdmRequiredDocument[]>([]);

  const [isUploadDetailsOpen, setIsUploadDetailsOpen] = useState<boolean>(false);
  const [selectedUpload, setSelectedUpload] = useState<FileSchema | undefined>(undefined);
  const [downloadUploadStatus, setDownloadUploadStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);
  const [deleteUploadStatus, setDeleteUploadStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);
  const [downloadAdmReqPdfStatus, setDownloadAdmReqPdfStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const applicationAppeal = useMemo(
    () => appeals.find((appeal) => appeal._id === application?.appeal_id),
    [application, appeals]
  );
  const applicationResidence = useMemo(
    () => residences.find((residence) => residence._id === applicationAppeal?.residence_id),
    [applicationAppeal, residences]
  );

  useEffect(() => {
    if (applicationResidence && userProfile) {
      const isFirstEnrollment =
        applicationResidence.mode !== ResidenceMode.DEFAULT
          ? true
          : userProfile.curriculum?.university.firstUniEnrollment
          ? true
          : false;
      const isAlreadyEnrolled =
        applicationResidence.mode !== ResidenceMode.DEFAULT
          ? true
          : userProfile.curriculum?.university.alreadyEnrolled
          ? true
          : false;
      const hasBachelor =
        applicationResidence.mode !== ResidenceMode.DEFAULT
          ? true
          : userProfile.curriculum?.university.alreadyEnrolled?.hasBachelor
          ? true
          : false;
      const parsedUploads: IAdmRequiredDocument[] = [];
      console.log(applicationResidence.admRequiredDocuments);
      applicationResidence.admRequiredDocuments.forEach((reqUpload) => {
        // se l'utente dichiara di non inviare isee viene rimosso isee e dsu
        if ((reqUpload.key === 'isee' || reqUpload.key === 'dsu') && userProfile.registry?.notSubmitIsee) {
          // skip it
        } else if (
          applicationResidence.mode !== ResidenceMode.INTERNATIONAL &&
          reqUpload.key === 'bachelorDegreeCertificate' &&
          !userProfile.curriculum?.degreeCertificate
        ) {
          // skip it
        } else if (reqUpload.key === 'diploma' && !userProfile.curriculum?.highSchoolCertificate) {
          // skip it
        } else if (
          (reqUpload.required.alreadyEnrolled && isAlreadyEnrolled) ||
          (reqUpload.required.firstEnrollment && isFirstEnrollment) ||
          (reqUpload.required.hasBachelor && hasBachelor)
        )
          parsedUploads.push(reqUpload);
      });
      setParsedRequiredUploads(parsedUploads);
    }
  }, [applicationResidence, userProfile]);

  const uploadFile = async (key: string, file: File) => {
    // console.log(key, file)
    const formData = new FormData();
    formData.append(key, file);
    // console.log(formData)
    try {
      const updatedApplication = await uploadApplicationUploadFn(formData);
      setApplication(updatedApplication);
      // console.log(res);
      sendNotification(t('ApplicationUploads.notification.uploadSuccess'), '', 'success', true, 5000);
    } catch (ex) {
      console.log(ex);
      sendNotification(t('ApplicationUploads.notification.uploadFails'), '', 'error', true, 5000);
    }
  };

  const completeApplication = async () => {
    if (application && application.status !== IApplicationStatus.APPLICATION_COMPLETE) {
      try {
        const completeApplicationRequest = await updateApplicationFn({
          status: IApplicationStatus.APPLICATION_COMPLETE,
        });
        setApplication(completeApplicationRequest);
        navigate('/application/conclusion');
      } catch (e: any) {
        console.log(e);
        // let message = undefined
        // if (e.response.data && typeof e.response.data === 'string')
        //     message = e.response.data
        // if (message === 'Please verify your account')
        //     message = 'Devi prima verificare il tuo account'
        sendNotification(t('ApplicationUploads.notification.impossible'), '', 'error', true, 5000);
      }
    } else navigate('/application/conclusion');
  };

  const deleteSelectedUpload = async () => {
    if (application && selectedUpload) {
      try {
        setDeleteUploadStatus(FetchStatus.LOADING);
        const updatedApplication = await deleteApplicationUploadFn(selectedUpload.key);
        setApplication(updatedApplication);
        sendNotification(t('ApplicationUploads.notification.a1'), '', 'success', true, 5000);
        setDeleteUploadStatus(FetchStatus.SUCCESS);
      } catch (e: any) {
        sendNotification(t('ApplicationUploads.notification.a2'), '', 'error', true, 5000);
        setDeleteUploadStatus(FetchStatus.ERROR);
      }
    }
  };

  const downloadUpload = async () => {
    if (application && selectedUpload) {
      try {
        setDownloadUploadStatus(FetchStatus.LOADING);
        const downloadedFile = await downloadApplicationUploadFn(selectedUpload.key);
        setDownloadUploadStatus(FetchStatus.SUCCESS);
        const file = new Blob([downloadedFile], {
          type: selectedUpload.mimetype,
        });
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // const pdfWindow = window.open();
        // if (pdfWindow)
        //     pdfWindow.location.href = fileURL;
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute('download', `${selectedUpload.originalName}`);
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        sendNotification(t('ApplicationUploads.notification.a3'), '', 'error', true, 5000);
        setDownloadUploadStatus(FetchStatus.ERROR);
      }
    }
  };

  const downloadAdmissionRequestPdf = async () => {
    if (application && userProfile) {
      try {
        setDownloadAdmReqPdfStatus(FetchStatus.LOADING);
        const admReqPdf = await downloadAdmissionReqPdfFn();
        setDownloadAdmReqPdfStatus(FetchStatus.SUCCESS);
        const file = new Blob([admReqPdf], { type: 'application/pdf' });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        // const pdfWindow = window.open();
        // if (pdfWindow)
        //     pdfWindow.location.href = fileURL;
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute('download', `${userProfile.registry?.lastName}_admissionRequest.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        sendNotification(t('ApplicationUploads.notification.a4'), '', 'error', true, 5000);
        setDownloadAdmReqPdfStatus(FetchStatus.ERROR);
        // setCompleteApplicationStatus(FetchStatus.ERROR);
      }
    }
  };

  return (
    <>
      <Header userProfile={userProfile} userSession={userSession} setUserLogout={() => setUserLogout(false)} />
      {selectedUpload && application && (
        <UploadDetailsDialog
          application={application}
          selectedUpload={selectedUpload}
          downloadUpload={downloadUpload}
          isOpen={isUploadDetailsOpen}
          onClose={() => setIsUploadDetailsOpen(false)}
          uploadDetail={selectedUpload}
          deleteUpload={deleteSelectedUpload}
          deleteUploadStatus={deleteUploadStatus}
          downloadUploadStatus={downloadUploadStatus}
        />
      )}
      {application && applicationResidence && applicationAppeal && (
        <SubHeader
          application={application}
          applicationAppeal={applicationAppeal}
          applicationResidence={applicationResidence}
          editApplication={openEditApplicationConfirm}
        />
      )}
      <div className="w-full flex flex-col md:flex-row justify-between flex-wrap pt-8">
        {application && applicationResidence && applicationAppeal && userProfile && (
          <DescriptionSidebar
            userProfile={userProfile}
            application={application}
            applicationAppeal={applicationAppeal}
            requiredUploads={parsedRequiredUploads}
          />
        )}
        <div className="md:animate-fade-in-right w-full md:w-8/12 flex flex-col  items-start md:pl-8 mt-8 md:mt-0">
          {application && applicationResidence && applicationAppeal && (
            <UploadsManager
              downloadAdmissionRequestPdf={downloadAdmissionRequestPdf}
              downloadAdmReqPdfStatus={downloadAdmReqPdfStatus}
              openUploadDetails={(upload: FileSchema) => {
                setSelectedUpload(upload);
                setIsUploadDetailsOpen(true);
              }}
              completeApplication={completeApplication}
              uploadFile={uploadFile}
              application={application}
              applicationAppeal={applicationAppeal}
              requiredUploads={parsedRequiredUploads}
              applicationResidence={applicationResidence}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ApplicationUploads;

function UploadsManager({
  completeApplication,
  application,
  applicationAppeal,
  requiredUploads,
  uploadFile,
  openUploadDetails,
  downloadAdmissionRequestPdf,
  downloadAdmReqPdfStatus,
  applicationResidence,
}: {
  completeApplication: () => void;
  application: IApplication;
  requiredUploads: IAdmRequiredDocument[];
  applicationAppeal: IAppeal;
  uploadFile: (key: string, file: File) => void;
  openUploadDetails: (upload: FileSchema) => void;
  downloadAdmissionRequestPdf: () => void;
  downloadAdmReqPdfStatus: FetchStatus;
  applicationResidence: IResidence;
}) {
  const { t } = useTranslation();
  const [characterizationChecked, setCharacterizationChecked] = useState();
  const [privacyChecked, setPrivacyChecked] = useState();

  const uploadsComplete = requiredUploads.every((requiredUpload) =>
    application.uploads.find((upload) => upload.key === requiredUpload.key)
  );
  const appealExpired = dayjs() > dayjs(applicationAppeal.expiryDate);

  return (
    <div className="flex flex-col items-start">
      {/*
      CMMP-733
      Non è più richiesta la produzione e la stampa della domanda di ammissione al concorso
      
      <div
        className={`p-4 flex flex-col md:flex-row justify-between items-center w-full rounded bg-slate-200`}
      >
        
        <p className="text-center md:text-left text-bluemazza text-md text-bold">
          Documento domanda di ammissione da scaricare,firmare
          scansionare/fotografare e caricare nell'apposito campo.
        </p>
        <button
          disabled={downloadAdmReqPdfStatus === FetchStatus.LOADING}
          onClick={downloadAdmissionRequestPdf}
          className="mt-4 md:mt-0 w-6/12 md:w-4/12 bluemazza-button"
        >
          {downloadAdmReqPdfStatus === FetchStatus.LOADING ? (
            <ScaleLoader color="white" />
          ) : (
            " Scarica PDF"
          )}
        </button>
      </div>
      */}
      <div className="w-full mt-8 flex flex-row items-center justify-between">
        <h3 className="text-xl font-bold text-bluemazza">{t('UploadsManager.a1')}</h3>
      </div>
      <div className="mt-8 w-full grid grid-cols-1 md:grid-cols-2 gap-8">
        {requiredUploads.map((requiredDoc, idx) => {
          const uploadExist = application.uploads.find((upload) => upload.key === requiredDoc.key);

          return (
            <div key={idx} className="flex flex-col items-start gap-2">
              <div className="flex flex-row items-center justify-between w-full">
                <label className="form-label" htmlFor={`${idx}.uploadInput`} style={{ marginBottom: 0 }}>
                  {requiredDoc.textToDisplay} ({requiredDoc.key === 'photo' ? 'JPEG,JPG,PNG' : 'PDF'})
                </label>
                {uploadExist && (
                  <button
                    onClick={() => openUploadDetails(uploadExist)}
                    className="rounded p-1 text-white border-bluemazza bg-bluemazza hover:bg-white hover:text-black transition-colors ease-in-out duration-300"
                  >
                    <FaEye />
                  </button>
                )}
              </div>
              <Dropzone
                maxSize={1097152}
                accept={requiredDoc.key === 'photo' ? 'image/jpeg,image/png' : 'application/pdf'}
                onDrop={(acceptedFiles) =>
                  acceptedFiles && acceptedFiles.length && uploadFile(requiredDoc.key, acceptedFiles[0])
                }
              >
                {({ getRootProps, getInputProps, isDragActive, fileRejections }) => {
                  const isError = fileRejections && fileRejections.length;
                  // console.log(fileRejections)
                  return (
                    <section className={`${isError ? 'text-white' : 'text-black'} w-full`}>
                      <div
                        key={idx}
                        {...getRootProps({
                          className: `dropzone ${
                            isError ? 'bg-red-700 hover:bg-red-600' : uploadExist && 'bg-green-600 hover:bg-green-700'
                          }`,
                        })}
                      >
                        <div className="mb-2 w-full flex flex-row justify-between items-center">
                          {isError ? (
                            <p className="text-white text-md underline">Errore nel caricamento del file</p>
                          ) : (
                            ''
                          )}
                          {uploadExist && (
                            <p className={`${uploadExist && 'text-white underline'}`}>
                              {uploadExist.originalName.length > 18
                                ? uploadExist.originalName.substring(0, 18) + '...'
                                : uploadExist.originalName}
                            </p>
                          )}
                          {uploadExist && (
                            <p className={`${uploadExist && 'text-white font-bold'}`}>
                              {(uploadExist.size / 1024).toFixed(1)} KB
                            </p>
                          )}
                        </div>
                        <input {...getInputProps()} id={`${idx}.uploadInput`} name={`${idx}.uploadInput`} />
                        <p className={`hidden md:inline ${uploadExist && 'text-white'}`}>{t('UploadsManager.a2')}</p>
                        <p className={`inline md:hidden ${uploadExist && 'text-white'}`}>{t('UploadsManager.a3')}</p>

                        {/* {fileRejections.map(({ file, errors }, idx) => {
                                            return (
                                                <li key={idx}>
                                                    {file.name} - {(file.size / (1024 * 1024)).toFixed(1)} MB
                                                    <ul>
                                                        {errors.map(e => <li key={e.code}>{e.message}</li>)}
                                                    </ul>

                                                </li>
                                            )
                                        })} */}
                      </div>
                      <p className="input-error">
                        {fileRejections?.length > 0 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t('UploadsManager.a4', {
                                size: (fileRejections[0].file.size / (1024 * 1024)).toFixed(1),
                              }),
                            }}
                          />
                        ) : null}
                      </p>
                    </section>
                  );
                }}
              </Dropzone>
            </div>
          );
        })}
      </div>
      <div className="w-full my-4 space-y-5">
        <p>{t('UploadsManager.a5')}</p>
        <h3 className="text-center md:text-left form-title">{t('UploadsManager.a6')}</h3>
        {applicationResidence.mode === ResidenceMode.DEFAULT ? (
          <>
            <div className="flex flex-row items-center justify-start gap-4">
              <input
                className="form-input w-auto"
                type="checkbox"
                defaultChecked={characterizationChecked}
                onChange={(e: any) => setCharacterizationChecked(e.target.checked)}
              />
              <p>{t('UploadsManager.a7')}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-4">
              <input
                className="form-input w-auto"
                type="checkbox"
                defaultChecked={privacyChecked}
                onChange={(e: any) => setPrivacyChecked(e.target.checked)}
              />
              <p>
                {t('UploadsManager.a8')} (
                <a
                  href={process.env.PUBLIC_URL + '/pdf/privacy-policy.pdf'}
                  target="_blank"
                  className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                  rel="noreferrer"
                >
                  {t('UploadsManager.a9')}
                </a>
                ){t('UploadsManager.a10')} (
                <a
                  href={process.env.PUBLIC_URL + '/pdf/informativa-candicati.pdf'}
                  target="_blank"
                  className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                  rel="noreferrer"
                >
                  {t('UploadsManager.a11')}
                </a>
                ) {t('UploadsManager.a12')}
              </p>
            </div>
          </>
        ) : null}
        {applicationResidence.mode === ResidenceMode.INTERNATIONAL ? (
          <>
            <div className="flex flex-row items-center justify-start gap-4">
              <input
                className="form-input w-auto"
                type="checkbox"
                defaultChecked={characterizationChecked}
                onChange={(e: any) => setCharacterizationChecked(e.target.checked)}
              />
              <p>
                I declare that I have read and understood the information on the processing of personal data. Read the{' '}
                <a
                  href="https://international.collegiomazza.it/privacy-policy/"
                  target="_blank"
                  className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className="flex flex-row items-center justify-start gap-4">
              <input
                className="form-input w-auto"
                type="checkbox"
                defaultChecked={privacyChecked}
                onChange={(e: any) => setPrivacyChecked(e.target.checked)}
              />
              <p>
                To have read and consent the
                <a
                  href="https://www.candidature.collegiomazza.it/wp-content/uploads/2020/06/Informativa-per-concorrenti.pdf"
                  target="_blank"
                  className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                  rel="noreferrer"
                >
                  Processing of Personal Data
                </a>
              </p>
            </div>
          </>
        ) : null}
        {applicationResidence.mode === ResidenceMode.GRADUATES ? (
          <>
            <div className="flex flex-row items-center justify-start gap-4">
              <input
                className="form-input w-auto"
                type="checkbox"
                defaultChecked={characterizationChecked}
                onChange={(e: any) => setCharacterizationChecked(e.target.checked)}
              />
              <p>{t('UploadsManager.a15')}</p>
            </div>
            <div className="flex flex-row items-center justify-start gap-4">
              <input
                className="form-input w-auto"
                type="checkbox"
                defaultChecked={privacyChecked}
                onChange={(e: any) => setPrivacyChecked(e.target.checked)}
              />
              <p>
                {t('UploadsManager.a16')} (
                <a
                  href="https://www.collegiomazza.it/privacy-policy/"
                  target="_blank"
                  className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                  rel="noreferrer"
                >
                  {t('UploadsManager.a17')}
                </a>
                ){t('UploadsManager.a18')}
                <a
                  href="https://www.collegiomazza.it/wp-content/uploads/2023/05/informativa-candicati.pdf"
                  target="_blank"
                  className="cursor-pointer underline transition-colors ease duration-300 hover:text-orange-500"
                  rel="noreferrer"
                >
                  {t('UploadsManager.a19')}
                </a>
              </p>
            </div>
          </>
        ) : null}

        <div className="w-full text-right">
          <button
            className="bluemazza-submit-button"
            disabled={!uploadsComplete || appealExpired || !privacyChecked || !characterizationChecked}
            onClick={completeApplication}
          >
            {t('UploadsManager.a13')}
          </button>
          {appealExpired && <p className="text-black text-xs underline">{t('UploadsManager.a14')}</p>}
        </div>
      </div>

      <div className="my-8 flex justify-center">
        <FeedbackCompletation />
      </div>
    </div>
  );
}

export function SubHeader({
  application,
  applicationResidence,
  applicationAppeal,
  editApplication,
}: {
  application: IApplication;
  applicationResidence: IResidence;
  applicationAppeal: IAppeal;
  editApplication: () => void;
}) {
  const { t } = useTranslation();
  const daysToExpire = dayjs(applicationAppeal?.expiryDate).diff(dayjs(), 'days');
  return (
    <div className="w-full flex flex-col md:flex-row justify-between items-center pt-8 mt-8">
      <>
        <div className="flex flex-col items-center md:items-start">
          <h3 className="text-center md:text-left font-extrabold text-black text-xl">{t('SubHeader.a1')}</h3>
          <h3 className="pt-2 md:pt-0 text-center md:text-left font-bold text-lightbluemazza text-lg justify-self-end md:justify-self-auto">
            {applicationResidence.name || '...'} ({dayjs(applicationAppeal.date).format('DD/MM/YYYY')})
          </h3>

          <h5
            className={`pt-2 md:pt-0  text-center md:text-left font-medium ${
              daysToExpire <= 0
                ? 'text-red-500'
                : daysToExpire <= 7
                ? 'text-orange-500'
                : daysToExpire <= 15
                ? 'text-yellow-500'
                : 'text-black'
            } text-md justify-self-end md:justify-self-auto underline`}
            dangerouslySetInnerHTML={{
              __html:
                dayjs(applicationAppeal?.expiryDate) < dayjs()
                  ? t('SubHeader.a2', { time: dayjs(applicationAppeal?.expiryDate).fromNow() })
                  : t('SubHeader.a3', { time: dayjs(applicationAppeal?.expiryDate).fromNow() }),
            }}
          ></h5>
        </div>
        <div className="flex flex-col items-center md:items-start pt-4 md:pt-0">
          <h3 className="text-center md:text-left font-extrabold text-black text-xl">{t('SubHeader.a7')}</h3>
          <h5
            className="text-center md:text-left font-bold text-green-500 text-lg"
            dangerouslySetInnerHTML={{
              __html: t('SubHeader.a4', { time: dayjs(application.updatedAt).format('DD/MM/YYYY') }),
            }}
          ></h5>
        </div>
        <div className="flex items-end gap-2 flex-col">
          <button
            disabled={dayjs() > dayjs(applicationAppeal.expiryDate)}
            onClick={editApplication}
            className=" bluemazza-button outline flex flex-row items-center gap-4 mt-4 md:mt-0"
          >
            <FaEdit /> {t('SubHeader.a5')}
          </button>
          {dayjs() > dayjs(applicationAppeal.expiryDate) && (
            <p className="text-black text-xs underline">{t('SubHeader.a5')}</p>
          )}
        </div>
      </>
    </div>
  );
}
