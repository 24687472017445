import { useEffect, useState } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { FetchStatus } from 'types';
import { IAppeal, IResidence, ResidenceMode } from 'types/api/residences';
import { IApplication } from 'types/api/applications';
import { useAuthentication } from 'context/authContext';
import { useResidences } from 'context/residencesContext';
import dayjs from 'utils/dayjs';
import { ScaleLoader } from 'react-spinners';
import { useApplication } from 'context/applicationContext';
import { Alert } from 'components/Shared/Alert/Alert';
import { Header } from 'components/header';
import { Disclosure, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

export default function CreateApplication() {
  const { t } = useTranslation();
  const { setUserLogout, userSession } = useAuthentication();
  const { residences, appeals } = useResidences();
  const {
    userProfile,
    application,
    createApplicationRequest,
    updateApplicationAppealRequest,
    createUpdateApplicationAppealFetchStatus,
  } = useApplication();

  const [selectedResidence, setSelectedResidence] = useState<IResidence | undefined>(undefined);
  const [residenceAppeals, setResidenceAppeals] = useState<IAppeal[]>([]);

  const [selectedAppeal, setSelectedAppeal] = useState<IAppeal | undefined>(undefined);
  const [selectedAppealError, setSelectedAppealError] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (selectedResidence)
      setResidenceAppeals(
        appeals.filter((appeal) => appeal.residence_id === selectedResidence._id && dayjs(appeal.expiryDate) > dayjs())
      );
  }, [appeals, selectedResidence]);

  useEffect(() => {
    if (residenceAppeals.length) setSelectedAppeal(residenceAppeals[0]);
    else setSelectedAppeal(undefined);
  }, [residenceAppeals]);

  useEffect(() => {
    residences.length && setSelectedResidence(residences[0]);
  }, [residences]);

  useEffect(() => {
    let candidacyAllowed = true;
    if (selectedAppeal && selectedAppeal.acceptCandidacyFromDate) {
      if (dayjs(selectedAppeal.acceptCandidacyFromDate) > dayjs())
        if (dayjs(selectedAppeal.acceptCandidacyFromDate).isSame(dayjs(), 'day'))
          //candidancy allowed only after today
          //if candidacy opens today
          candidacyAllowed = true;
        else candidacyAllowed = false;
      else candidacyAllowed = true;
    }

    setSelectedAppealError(!candidacyAllowed);
  }, [selectedAppeal]);

  const handleSubmitApplication = (appeal_id: string, promoCode?: string) => {
    if (application) updateApplicationAppealRequest(appeal_id, promoCode);
    else createApplicationRequest(appeal_id, promoCode);
  };

  return (
    <div className="px-4 flex flex-col items-center gap-4">
      <Header setUserLogout={() => setUserLogout(false)} userSession={userSession} userProfile={userProfile} />

      <div className="shadow rounded border flex flex-col items-start justify-center w-full max-w-xl ">
        <div className="w-full p-4 bg-bluemazza rounded-t">
          <h3
            className="text-xl font-bold text-white"
            dangerouslySetInnerHTML={{
              __html: t('CreateApplication.title', { years: `${dayjs().year()}/${dayjs().add(1, 'year').year()}` }),
            }}
          ></h3>
        </div>

        {application && (
          <div className="w-full px-4 pt-4">
            <ApplicationErrorAlert application={application} appeals={appeals} />
          </div>
        )}

        {appeals.length ? (
          <div className="p-4 w-full flex flex-col items-center">
            <p className="my-8">{t('CreateApplication.description')}</p>
            <div className="w-full mb-4">
              <label className="form-label" htmlFor="firstName">
                {t('CreateApplication.residence.label')}
              </label>

              {residences
                .sort((a, b) =>
                  a.mode === ResidenceMode.INTERNATIONAL ? -100 : a.mode === ResidenceMode.GRADUATES ? -50 : 0
                )
                .map((residence, idx) => (
                  <label htmlFor={residence._id} className="flex">
                    <input
                      type="radio"
                      key={idx}
                      value={residence._id}
                      id={residence._id}
                      checked={selectedResidence?._id === residence._id}
                      onChange={(e) => {
                        const residenceExist = residences.find((residence) => residence._id === e.target.value);
                        if (residenceExist) setSelectedResidence(residenceExist);
                      }}
                    />
                    <span className="relative -top-1 ml-4">{residence.name}</span>
                  </label>
                ))}
            </div>
            {selectedResidence?.promoCodeEnabled && (
              <div className="w-full mb-4">
                <label className="form-label" htmlFor="promoCode">
                  {t('CreateApplication.promoCode.label')}
                </label>
                <input
                  className="form-input"
                  type="text"
                  onChange={(e) => setPromoCode(e.target.value)}
                  name="promoCode"
                  id="promoCode"
                  placeholder={t('CreateApplication.promoCode.placeholder')}
                />
              </div>
            )}
            <div className="w-full mb-4">
              <label className="form-label" htmlFor="appeal">
                {t('CreateApplication.appeal.label')}
              </label>
              {selectedResidence && residenceAppeals.length === 0 ? (
                <p>{t('CreateApplication.appeal.none')}</p>
              ) : (
                <select
                  disabled={!selectedResidence}
                  value={selectedAppeal?._id}
                  onChange={(e) => {
                    const appealExist = appeals.find((appeal) => appeal._id === e.target.value);
                    // console.log(appealExist)
                    if (appealExist) {
                      setSelectedAppeal(appealExist);
                    }
                  }}
                  className="form-input"
                  id="appeal"
                >
                  {residenceAppeals.map((appeal, idx) => (
                    <option
                      key={idx}
                      value={appeal._id}
                      dangerouslySetInnerHTML={{
                        __html: t('CreateApplication.appeal.option', {
                          date: `${dayjs(appeal.date).format('DD/MM/YYYY')}`,
                          expiryDate: dayjs(appeal.expiryDate).fromNow(),
                        }),
                      }}
                    ></option>
                  ))}
                </select>
              )}
              {selectedAppealError && selectedAppeal?.acceptCandidacyFromDate && (
                <div className="mt-4">
                  <Alert
                    status="WARNING"
                    title={t('CreateApplication.alert.title')}
                    description={t('CreateApplication.alert.description', {
                      date: dayjs(selectedAppeal.acceptCandidacyFromDate).format('DD/MM/YYYY'),
                      days: dayjs(selectedAppeal.acceptCandidacyFromDate).diff(dayjs(), 'day'),
                    })}
                  />
                </div>
              )}
            </div>
            <div className="self-center flex items-center justify-center w-full">
              <button
                onClick={() =>
                  selectedAppeal && !selectedAppealError && handleSubmitApplication(selectedAppeal._id, promoCode)
                }
                disabled={!selectedAppeal || selectedAppealError}
                className="bluemazza-button"
                type="submit"
              >
                {createUpdateApplicationAppealFetchStatus === FetchStatus.LOADING ? (
                  <div className="flex flex-row items-center">
                    <ScaleLoader color="white" /> <p className="ml-1">{t('CreateApplication.onCreation')}</p>{' '}
                  </div>
                ) : (
                  <div className="flex flex-row items-center">
                    <FaChevronRight /> <p className="ml-1">{t('CreateApplication.next')}</p>
                  </div>
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col gap-1 items-center">
            <p className="text-lg font-bold">{t('CreateApplication.noAppeal.title')}</p>
            <p className="text-md font-medium">{t('CreateApplication.noAppeal.description')}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function ApplicationErrorAlert({ application, appeals }: { application: IApplication; appeals: IAppeal[] }) {
  const { t } = useTranslation();
  const appealExist = appeals.find((appeal) => appeal._id === application.appeal_id);

  return (
    <Disclosure defaultOpen={true}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`transition-colors ease-in-out duration-300 flex justify-between w-full px-4 py-2 text-sm font-medium text-left shadow border border-orange-500 ${
              open ? 'bg-orange-500 text-white rounded-t' : 'bg-orange-500 text-white rounded'
            } hover:shadow hover:text-white`}
          >
            <div className="w-full flex flex-row justify-between items-center mr-4">
              <p>
                {!appealExist
                  ? t('ApplicationErrorAlert.noAppeal')
                  : dayjs(appealExist.expiryDate) < dayjs() && t('ApplicationErrorAlert.applicationClose')}
              </p>
            </div>
            <FaChevronDown className={`${open ? ' transform rotate-180' : ''} w-5 h-5`} />
          </Disclosure.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500 border border-orange-700 rounded-b">
              <div className="flex flex-col items-start justify-between">
                {!appealExist ? (
                  <>
                    <p>{t('ApplicationErrorAlert.appealNotExist.title')}</p>
                    <p>{t('ApplicationErrorAlert.appealNotExist.description1')}</p>
                    <p className="font-bold underline">{t('ApplicationErrorAlert.appealNotExist.description2')}</p>
                  </>
                ) : (
                  dayjs(appealExist.expiryDate) < dayjs() && (
                    <>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t('ApplicationErrorAlert.appealExpired.title', {
                            days: dayjs(appealExist.expiryDate).fromNow(),
                            date: dayjs(appealExist.expiryDate).format('DD/MM/YYYY'),
                          }),
                        }}
                      ></p>
                      <p className="font-bold underline">{t('ApplicationErrorAlert.appealExpired.description1')}</p>
                    </>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
