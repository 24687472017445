import { boolean, number, object, string, z } from 'zod';
import { ResidenceMode } from './residences';

export enum IApplicationStatus {
  APPLICATION_CREATED = 'Domanda creata',
  APPLICATION_COMPLETE = 'Domanda completa',
  APPLICATION_ACCEPTED = 'Invitato al colloquio',
  APPLICATION_REJECTED = 'Domanda rifiutata/ritarata',
  PRESENT = 'Presente al concorso',
  WITHDRAWN = 'Ritirato dal concorso',
  WINNER = 'Vincitore del concorso',
  LOSER = 'Non vincitore del concorso',
}

export enum IApplicationFinalStatus {
  APPLICATION_ACCEPTED = 'Invitato al colloquio',
  APPLICATION_REJECTED = 'Domanda rifiutata/ritarata',
  PRESENT = 'Presente al concorso',
  WITHDRAWN = 'Ritirato dal concorso',
  WINNER = 'Vincitore del concorso',
  LOSER = 'Non vincitore del concorso',
}

export const fileSchema = object({
  key: string(),
  name: string(),
  originalName: string(),
  size: number(),
  encoding: string(),
  truncated: boolean(),
  mimetype: string(),
  md5: string(),
});
export type FileSchema = z.infer<typeof fileSchema>;

export type IApplication = {
  _id: string;
  user_id: string;
  promoCode?: string;
  appeal_id: string;
  status: IApplicationStatus;
  uploads: FileSchema[];
  createdAt: Date;
  updatedAt: Date;
};

export type IQuestion = {
  _id: string;
  text: string;
  isFirstUniEnrollment: boolean;
  isAlreadyEnrolled: boolean;
  mode: ResidenceMode;
};

export interface ICreateApplication {
  appeal_id: string;
  promoCode?: string;
}

export interface IUpdateApplication {
  appeal_id?: string;
  promoCode?: string;
  status?: IApplicationStatus;
}
