import { UserSessionModel } from 'types';
import { updateUserProfileType } from 'types/zod';

export function Header({
  setUserLogout,
  userProfile,
  userSession,
}: {
  setUserLogout: () => void;
  userProfile: updateUserProfileType | undefined;
  userSession: UserSessionModel | undefined;
}) {
  return (
    <div className="w-full flex  flex-row justify-between items-center">
      <div className="w-6/12 md:w-2/12">
        <img
          src="/images/logo.png"
          className="transition ease duration-300 cursor-pointer object-contain hover:scale-110"
        />
      </div>
      <div className="self-end flex flex-col items-end pt-4 md:pt-0">
        <p
          onClick={setUserLogout}
          className="cursor-pointer text-bluemazza text-md font-bold transition-colors ease duration-300 hover:text-orange-500"
        >
          Logout
        </p>
        <p className="text-lightbluemazza text-xl font-extrabold">
          {userProfile?.registry && userProfile.registry.firstName && userProfile.registry.lastName
            ? `${userProfile.registry.firstName} ${userProfile.registry.lastName}`
            : `${userSession?.email}`}
        </p>
      </div>
    </div>
  );
}
