import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { FaFingerprint, FaUserGraduate, FaUsers, FaUserEdit, FaPaperPlane, FaUpload } from 'react-icons/fa';

import { FetchStatus, StepModel } from 'types';
import { IAppeal, IResidence, ResidenceMode } from 'types/api/residences';
import { IApplication } from 'types/api/applications';

import Registry from './registry';
import Curriculum from './curriculum';
import FamilyComposition from './familyComposition';

import { useAuthentication } from 'context/authContext';
import sendNotification from 'utils/notifications';

import { updateUserProfileSchema, updateUserProfileType, getUserProfileSchema } from 'types/zod';

import { useApplication } from 'context/applicationContext';
import { useResidences } from 'context/residencesContext';
import dayjs from 'dayjs';
import { deepEqual } from 'utils';
import PersonalProfile from 'pages/application/form/personalProfile';
import SendApplication from 'pages/application/form/sendApplication';
import { Header } from 'components/header';

import HelpDialog from 'components/Application/HelpDialog/HelpDialog';
import { updateUserProfileFn } from 'api/userApi';

import { useTranslation } from 'react-i18next';

export const steps: StepModel[] = [
  {
    name: 'Anagrafica',
    path: 'registry',
    icon: FaFingerprint,
    component: Registry,
  },
  {
    name: 'Curriculum',
    path: 'curriculum',
    icon: FaUserGraduate,
    component: Curriculum,
  },
  {
    name: 'Gruppo Familiare',
    path: 'familyComposition',
    icon: FaUsers,
    component: FamilyComposition,
  },
  {
    name: 'Profilo Personale',
    path: 'personalProfile',
    icon: FaUserEdit,
    component: PersonalProfile,
  },
  {
    name: 'Invia domanda',
    path: 'sendApplication',
    icon: FaPaperPlane,
    component: SendApplication,
  },
];
function Form() {
  const navigate = useNavigate();
  const { step } = useParams();

  const { setUserLogout, userSession } = useAuthentication();

  const {
    application,
    applicationAppeal,
    applicationAppealResidence,
    applicationFetchStatus,
    userProfile,
    setUserProfile,
    userProfileFetchStatus,
    deleteApplication,
    deleteApplicationStatus,
    deleteUserProfile,
    deleteProfileStatus,
  } = useApplication();
  const { residences, appeals } = useResidences();

  const [formUserProfile, setFormUserProfile] = useState<updateUserProfileType | undefined>(undefined);
  const [updateUserProfileFetchStatus, setUpdateUserProfileFetchStatus] = useState<FetchStatus>(FetchStatus.UNDEFINED);

  const [helpDialogOpen, setHelpDialogOpen] = useState<boolean>(false);

  const [currentStep, setCurrentStep] = useState<StepModel | undefined>(undefined);
  const [prevStep, setPrevStep] = useState<StepModel | undefined>(undefined);
  const [nextStep, setNextStep] = useState<StepModel | undefined>(undefined);

  const formSteps = useMemo(() => {
    if (applicationAppealResidence?.mode === ResidenceMode.INTERNATIONAL) {
      return steps.filter((item) =>
        ['registry', 'curriculum', 'personalProfile', 'sendApplication'].includes(item.path)
      );
    } else if (applicationAppealResidence?.mode === ResidenceMode.GRADUATES) {
      return steps.filter((item) =>
        ['registry', 'curriculum', 'personalProfile', 'sendApplication'].includes(item.path)
      );
    }
    return steps;
  }, [applicationAppealResidence?.mode]);

  const handleUserProfileUpdate = useCallback(
    (profile: updateUserProfileType) => {
      const parsedProfile = updateUserProfileSchema.parse(profile);
      console.log('parsedProfile', parsedProfile);
      setFormUserProfile(parsedProfile);
    },
    [updateUserProfileSchema]
  );
  //Check if current/next step is enabled based on the current step position
  useEffect(() => {
    const currentStepIndex = formSteps.findIndex((step) => step.path === currentStep?.path);
    // console.log(currentStepIndex)
    if (currentStepIndex === -1) {
      setPrevStep(undefined);
      setNextStep(undefined);
    } else {
      if (currentStepIndex === 0) setPrevStep(undefined);
      else setPrevStep(formSteps[currentStepIndex - 1]);
      if (currentStepIndex === formSteps.length - 1) setNextStep(undefined);
      else setNextStep(formSteps[currentStepIndex + 1]);
    }
  }, [currentStep, formSteps]);
  //Check if current/next step is enabled based on the current step position

  //Fetch the userProfile and update the form with it
  useEffect(() => {
    const fetchAndSetProfile = async () => {
      // const fetchedProfile = await fetchUserProfile()
      if (userProfile) {
        sendNotification('Dati recuperati con successo', '', 'success', true, 5000);
        setFormUserProfile(userProfile);
      } else {
        sendNotification('Benvenuto! Procedi alla candidatura compilando il form', '', 'success', true, 10000);
      }
    };

    fetchAndSetProfile();
  }, []);
  //Fetch the userProfile and update the form with it

  const updateUserProfile = async (profile: updateUserProfileType) => {
    // const values = getValues()
    // console.log(values)
    const parsedProfile = updateUserProfileSchema.parse(profile);
    // console.log(parsedProfile)
    setUpdateUserProfileFetchStatus(FetchStatus.LOADING);
    // console.log("Updating remote profile...")
    // console.log(typeof parsedProfile.registry?.birthday, parsedProfile.registry?.birthday)
    try {
      const updatedProfile = await updateUserProfileFn(parsedProfile);
      setUpdateUserProfileFetchStatus(FetchStatus.SUCCESS);
      const parsedUpdatedProfile = getUserProfileSchema.parse(updatedProfile);
      sendNotification('Dati aggiornati', 'Dati aggiornati correttamente', 'success', true, 5000);
      return parsedUpdatedProfile;
    } catch (e: any) {
      // console.error(e)
      sendNotification('Aggiornamento dati fallito', 'Aggiornamento dati fallito', 'error', true, 5000);
      setUpdateUserProfileFetchStatus(FetchStatus.ERROR);
      return null;
    }
  };

  //Update userProfiel whenever it is update by the subforms
  useEffect(() => {
    const updateAndSyncProfile = async () => {
      if (formUserProfile) {
        const updatedProfile = await updateUserProfile(formUserProfile);
        if (updatedProfile) setUserProfile(updatedProfile);
      }
    };

    // if (JSON.stringify(userProfile) !== JSON.stringify(fetchedUserProfile)) {
    console.log(
      '^^^^',
      userProfile,
      formUserProfile,
      userProfile && formUserProfile ? deepEqual(formUserProfile, userProfile) : 'non serve deep'
    );
    if (!userProfile || (formUserProfile && userProfile && !deepEqual(formUserProfile, userProfile))) {
      // console.log(formUserProfile, userProfile)
      updateAndSyncProfile();
    }
  }, [formUserProfile]);
  //Update userProfiel whenever it is update by the subforms

  useEffect(() => {
    if (!step) navigate(`/application/form/${steps[0].path}`);
    const stepInSteps = formSteps.find((s) => s.path === step);
    if (stepInSteps) setCurrentStep(stepInSteps);
    else navigate(`/application/form/${formSteps[0].path}`);
  }, [step]);

  return (
    <>
      <HelpDialog
        isOpen={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        deleteApplicationRequest={deleteApplication}
        deleteApplicationRequestStatus={deleteApplicationStatus}
        deleteProfileRequest={deleteUserProfile}
        deleteProfileRequestStatus={deleteProfileStatus}
      />
      <Header userProfile={userProfile} userSession={userSession} setUserLogout={() => setUserLogout(false)} />
      <FormSubHeader
        profile={formUserProfile}
        application={application}
        residences={residences}
        appeals={appeals}
        updateUserProfileFetchStatus={updateUserProfileFetchStatus}
        openHelpDialog={() => setHelpDialogOpen(true)}
      />
      {currentStep && (
        <currentStep.component
          currentStep={currentStep}
          steps={formSteps}
          prevStep={prevStep}
          nextStep={nextStep}
          profile={formUserProfile}
          profileFetchStatus={userProfileFetchStatus}
          setProfile={handleUserProfileUpdate}
          applicationAppeal={applicationAppeal}
          applicationAppealResidence={applicationAppealResidence}
        />
      )}
    </>
  );
}

export default Form;

interface IFormSubHeader {
  residences: IResidence[];
  appeals: IAppeal[];
  application: IApplication | undefined;
  updateUserProfileFetchStatus: FetchStatus;
  profile: updateUserProfileType | undefined;
  openHelpDialog: () => void;
}
const FormSubHeader: React.FC<IFormSubHeader> = ({
  residences,
  appeals,
  application,
  updateUserProfileFetchStatus,
  profile,
  openHelpDialog,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [applicationResidence, setApplicationResidence] = useState<IResidence | undefined>(undefined);
  const [applicationAppeal, setApplicationAppeal] = useState<IAppeal | undefined>(undefined);

  const [completionPercentage, setCompletionPercentage] = useState<number>(0);

  const daysToExpire = dayjs(applicationAppeal?.expiryDate).diff(dayjs(), 'days');

  useEffect(() => {
    let perc = 0;
    if (profile) {
      if (profile.registry) perc += 25;
      if (profile.curriculum) perc += 25;
      if (profile.familyComposition) perc += 25;
      if (profile.questions?.length) perc += 25;
    }
    setCompletionPercentage(perc);
  }, [profile]);

  useEffect(() => {
    if (application) {
      const relatedAppeal = appeals.find((appeal) => appeal._id === application.appeal_id);
      if (relatedAppeal) {
        setApplicationAppeal(relatedAppeal);
        const relatedResidence = residences.find((residence) => residence._id === relatedAppeal.residence_id);
        if (relatedResidence) setApplicationResidence(relatedResidence);
      }
    }
  }, [application]);

  return (
    <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center pt-8">
      <div className="flex flex-col items-center md:items-start">
        <h3 className="text-center md:text-left font-extrabold text-black text-xl">{t('SubHeader.a1')}</h3>
        <h3 className="pt-2 md:pt-0  text-center md:text-left  font-bold text-lightbluemazza text-lg justify-self-end md:justify-self-auto">
          {applicationResidence?.name || '...'} ({dayjs(applicationAppeal?.date).format('DD/MM/YYYY')})
        </h3>
        <h5
          className={`pt-2 md:pt-0  text-center md:text-left font-medium ${
            daysToExpire <= 0
              ? 'text-red-500'
              : daysToExpire <= 7
              ? 'text-orange-500'
              : daysToExpire <= 15
              ? 'text-yellow-500'
              : 'text-black'
          } text-md justify-self-end md:justify-self-auto underline`}
          dangerouslySetInnerHTML={{
            __html:
              dayjs(applicationAppeal?.expiryDate) < dayjs()
                ? t('SubHeader.a2', { time: dayjs(applicationAppeal?.expiryDate).fromNow() })
                : t('SubHeader.a3', { time: dayjs(applicationAppeal?.expiryDate).fromNow() }),
          }}
        ></h5>

        {/* <div onClick={openHelpDialog} className=" flex flex-row items-center pt-2 md:pt-0  text-center md:text-left  font-medium text-orange-500 text-sm justify-self-end md:justify-self-auto cursor-pointer transition ease duration-300 hover:underline">
                    <FaRegQuestionCircle /> <span className="pl-2"> Stai riscontrando dei problemi?</span>
                </div> */}
      </div>
      <div className="hidden md:flex flex-col items-start md:items-end pt-4 md:pt-0">
        <h3 className="text-center md:text-left  font-extrabold text-black text-xl">{t('SubHeader.a7')}</h3>
        <p className={` ${completionPercentage < 100 ? 'text-orange-500' : 'text-green-500'} text-lg font-bold`}>
          {completionPercentage === 100 ? t('SubHeader.a10') : t('SubHeader.a11')}
        </p>
        <p className={` ${completionPercentage < 100 ? 'text-orange-500' : 'text-green-500'} text-md font-medium`}>
          {t('FormStepManager.description3', { completionPercentage })}
        </p>
        {completionPercentage == 100 && (
          <p
            onClick={() => navigate('/application/form/sendApplication')}
            className={` text-green-500 text-sm font-medium underline underline-offset-4 cursor-pointer transition ease duration-300 hover:text-green-600 `}
          >
            {t('FormStepManager.description4')}
          </p>
        )}

        {/* <p onClick={saveForm} className="cursor-pointer hover:text-green-200 text-green-500 text-md font-medium">Salva dati</p> */}
      </div>
    </div>
  );
};
