import Modal from 'components/Shared/Modal/Modal';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';

interface IQuestionsResetDialog {
  isOpen: boolean;
  onClose: () => void;
  resetQuestions: () => void;
}

const QuestionsResetDialog: React.FC<IQuestionsResetDialog> = ({ isOpen, onClose, resetQuestions }) => {
  const { t } = useTranslation();
  const modalTitle = t('QuestionsResetDialog.title');

  const onConfirmClick = () => {
    resetQuestions();
    onClose();
  };
  return (
    <Modal title={modalTitle} isOpen={isOpen} onClose={onClose} type="warning">
      <div className="p-4 flex flex-col justify-between items-start gap-2 w-full">
        <p className="text-md text-medium">{t('QuestionsResetDialog.description')}</p>
        <div className="w-full mt-4 flex flex-row items-center justify-between">
          <button onClick={onClose} className="bluemazza-button">
            {t('QuestionsResetDialog.cancel')}
          </button>
          <button onClick={onConfirmClick} className="red-button">
            <span className="flex flex-row items-center gap-2">
              <FaTrashAlt />
              {t('QuestionsResetDialog.submit')}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default QuestionsResetDialog;
